.camp-tab {
  font-size: 14px;
  color: #565656;
  position: relative;
  height: 39px;
  .common {
    position: absolute;
    background-color: #faf2db;
    border-radius: 16px 16px 0px 0px;
    width: 55.4%;
    line-height: 32px;
    bottom: 0px;
  }
  .left {
    left: 0;
    padding-left: 42px;
  }
  .right {
    right: 0;
    padding-left: 58px;
  }

  .left-selected {
    background: url("~/assets/fullstack/p2@3x.png");
    font-weight: bold;
    color: #2a2220;
    line-height: 39px;
    width: 100%;
    background-size: cover;
    padding-left: 42px;
    pointer-events: none;
    z-index: 1;
  }
  .right-selected {
    background: url("~/assets/fullstack/p3@3x.png");
    font-weight: bold;
    color: #2a2220;
    text-align: center;
    line-height: 39px;
    width: 100%;
    background-size: cover;
    text-align: right;
    padding-right: 30px;
    pointer-events: none;
    z-index: 1;
  }
}
@media screen and (min-width: 1175px) {
  .camp-tab {
    display: none;
  }
}
