.root-comment {
  padding-bottom: 15px;
}

.root-comment+.root-comment {
  border-top: 1px solid #D8D8D8;
  padding-top: 15px;
}

.sub-comment {
  background: #F7F7F7;
  padding: 10px 0;
  margin-top: 15px;

  .form-reply,
  .form-update {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: @screen-md-min) {
    padding: 20px 0;
  }
}

.comment {
  display: flex;

  .aside {
    flex: 0 0 32px;

    .ant-avatar {
      width: 32px;
      height: 32px;
    }

    @media (min-width: @screen-md-min) {
      flex: 0 0 50px;

      .ant-avatar {
        width: 50px;
        height: 50px;
      }
    }
  }

  .body {
    flex: 1;
    padding: 0 10px;

    @media (min-width: @screen-md-min) {
      padding: 0 20px;
    }
  }
}

.root-comment>.extra {
  margin-left: 32px;

  .form-reply {
    background: #efefef;
    padding: 10px;
    margin-top: 10px;
  }

  .form-update {
    background: #efefef;
    padding: 10px;
    margin-top: 10px;
  }

  @media (min-width: @screen-md-min) {
    margin-left: 50px;

    .form-reply {
      padding: 20px;
    }

    .form-update {
      padding: 20px;
    }
  }
}

.meta {
  height: 20px;
  line-height: 20px;
  vertical-align: middle !important;
  margin-bottom: 5px;
}

.comment-content.deleted {
  color: #939393;
  background-color: #e9e9e9;
  padding: 3px 8px;

  & a {
    text-decoration: underline;
  }
}

.post-comment-extra .ant-menu-item::after {
  border: none !important;
}