.create-post-btns-wrapper {
  margin-bottom: 10px !important;
  .ant-card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 5px !important;
    .ant-btn {
      width: 45%;
      margin-top: 0 !important;
    }
  }
}
.post-report-records-modal {
  .ant-space-item {
    width: 100%;
  }
}
.apk-upload-modal {
  position: absolute;
  top: 30px;
  width: 700px;
  padding: 20px 20px;
}

.job-placeholder {
  position: absolute;
  top: 70px;
  left: 20px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  pointer-events: none;
  white-space: pre-line;
  ul {
    padding: 10px 0px 10px 15px;
  }
  li {
    padding: 3px 0;
  }
}

@media (max-width: @screen-md-min) {
  .job-placeholder {
    font-size: 12px;
    ul {
      padding: 10px 10px 0 10px;
    }
  }
}
