.category-link {
  border-radius: 3px;
  padding: 0.1em 0.3em;
  font-size: 0.9em;
}

.post-item {
  border-bottom: 1px solid #f7f7f7;
  display: flex;
  padding: 15px 0;
  align-items: center;

  .aside {
    flex: 0 0 40px;
    text-align: left;

    .ant-avatar {
      width: 30px;
      height: 30px;
    }
  }

  .body {
    flex: 1;
    overflow: hidden;
  }

  .extra {
    flex: 0 0 40px;
    text-align: right;
  }

  .post-title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-weight: 400;
  }

  .post-title__hidden-tag {
    font-size: 14px;
    border-radius: 3px;
    color: white;
    background: #f9ba48;
    padding: 0 4px;
    margin-right: 8px;
  }

  &.deleted .post-title a {
    text-decoration: line-through;
    pointer-events: none;
  }

  @media (min-width: @screen-md-min) {
    .aside {
      flex: 0 0 80px;

      .ant-avatar {
        width: 60px;
        height: 60px;
      }
    }
  }
}

/**
 * Post Charge
 */
.charge-button {
  cursor: pointer;
  width: 104px;
  height: 104px;
  // background: url(/static/icons/charge-circle@2x.png) 50% no-repeat;
  // background-size: contain;
  text-align: center;
  padding-top: 23px;
  font-size: 12px;
  color: #ffffff;
}

.post-contact-info {
  color: #f4c876;
  border: 1px solid #f4c876;
  border-radius: 5px;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  -webkit-box-shadow: 0px 1px 2px 0px #f5f5f3;
  -moz-box-shadow: 0px 1px 2px 0px #f5f5f3;
  box-shadow: 0px 1px 2px 0px #f5f5f3;
}

.post-comment-extra {
  .ant-menu-horizontal {
    border-bottom: 0px;

    .ant-menu-item {
      padding: 0px;
      margin-right: 15px;

      &:hover {
        border-bottom-color: transparent;
      }
    }

    .ant-menu-item-selected {
      border-bottom-color: transparent;
    }
  }
}

.post-action-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #f9ba48;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 15px 10px;
  font-size: 12px;
  color: #f9ba48;
  cursor: pointer;
}

.post-action-button-active {
  background-color: #f9ba48;
  color: white;
}

.post-list-vote-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  gap: 5px;
  color: #7b7b7b;

  .anticon {
    cursor: pointer;
  }

  .upvote path,
  .downvote path {
    fill: #9b9b9b;
  }

  .active {
    color: #f9ba48;
    .upvote path {
      fill: #f9ba48;
      stroke: #f9ba48;
      stroke-width: 30;
    }
    .downvote path {
      fill: #444444;
      stroke: #444444;
      stroke-width: 30;
    }
  }

  .post-vote-count {
    width: 30px;
    text-align: center;
  }
}
