.owner-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  background-color: white;
  .left {
    display: flex;
    column-gap: 13px;
    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #f9ba48;
    }

    .nickname {
      font-size: 16px;
      color: #4a4a4a;
    }
    .create-time {
      font-size: 12px;
      color: #9b9b9b;
    }
  }
}
.city-rules-card {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #9b9b9b;
  list-style-type: none;

  li {
    background: url("~public/static/icons/list.png") left center no-repeat;
    padding-left: 20px;
    line-height: 25px;
  }
}
.city-members-card {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 5px 0 7px 0;
  max-height: 264px;
  overflow-y: scroll;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f9ba48;
  }
  .more {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    color: #7b7b7b;
    font-size: 30px;
    cursor: pointer;
    &::after {
      content: "...";
      position: absolute;
      font-size: 26px;
      left: 50%;
      transform: translate(-50%, -15%);
    }
  }
}
.join-btn-big {
  width: 100%;
  background-color: #f9ba48;
  font-size: 16px;
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 10px 0;
  margin-top: 20px;
  cursor: pointer;
}
.joined-big {
  background-color: #f0f0f0;
  color: #7b7b7b;
}
.city-group-card {
  max-height: 264px;
  overflow-y: scroll;
}

.city-details-wrapper {
  position: relative;
  .site-swiper {
    z-index: 0;
  }
  .city-details {
    position: absolute;
    bottom: 30px;
    left: 65px;
    z-index: 1;
    .top {
      display: flex;
      align-items: center;
      .city-name {
        font-size: 40px;
        font-weight: 600;
        color: white;
      }
    }
    .bottom {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      column-gap: 15px;
      .index {
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: white;
        .text {
          line-height: 10px;
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
.city-menu-wrapper {
  display: flex;
  font-size: 16px;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  div {
    margin-left: 10px;
    margin-right: 10px;
    color: #4a4a4a;
    cursor: pointer;
    &:hover {
      color: #f9ba48;
    }
  }
  .active {
    color: #f9ba48;
  }
}

.owner-wrapper {
  display: none;
}

@media screen and (max-width: 1175px) {
  .city-details-wrapper {
    .city-details {
      position: absolute;
      bottom: 10px;
      left: 10px;
      .top {
        .city-name {
          font-size: 24px;
        }
        .hide-in-mp {
          display: none;
        }
      }
      .bottom {
        margin-top: 10x;
        row-gap: 5px;
        .index {
          column-gap: 5px;
          font-size: 10px;
          .text {
            line-height: 9px;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .owner-wrapper {
    display: block;
    background-color: white;
    padding: 15px 20px;
    border-radius: 8px;
    .city-tag-wrapper {
      justify-content: space-between;
      .tag {
        padding: 2px 10px;
        font-size: 14px;
      }
    }
    .owner-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        color: #4a4a4a;
        display: flex;
        column-gap: 10px;
        align-items: center;

        .nickname {
          width: 80px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;
          font-weight: 500;
          color: #4a4a4a;
        }
        .create-time {
          font-size: 13px;
        }
      }
      .join-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        line-height: 24px;
        margin-left: 9px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: 500;
        color: #f9ba48;
        border: 1px solid #f9ba48;
        background-color: white;
        border-radius: 20px;
        cursor: pointer;
      }
      .joined {
        color: #7b7b7b;
        border: none;
        background-color: #f0f0f0;
      }
    }
    .line {
      display: block;
      width: 100%;
      height: 1px;
      background-color: #f0f0f0;
      margin: 15px 0;
    }
    .members-wrapper {
      display: flex;
      align-items: center;
      column-gap: 5px;
      margin-top: 15px;
      .more-users {
        position: relative;
        width: 22px;
        height: 22px;
        font-size: 10px;
        border-radius: 50%;
        background-color: #f0f0f0;
        color: #7b7b7b;
        &::after {
          content: "...";
          position: absolute;
          font-size: 15px;
          left: 50%;
          transform: translate(-50%, -20%);
        }
      }
      .total-count {
        font-size: 14px;
        font-weight: 400;
        margin-left: 10px;
        span {
          color: #f9ba48;
        }
      }
    }
  }
  .city-menu-wrapper {
    background-color: inherit;
    padding: 15px 0;
    margin-bottom: 0;
  }
}
