.cities-container {
  margin-top: -20px;
  margin-bottom: 55px;
  .small-container {
    width: 1200px;
    margin: 0 auto;
    .create-city-block {
      display: none;
    }
    .event-swiper {
      margin: 40px 0 80px 0;
      height: 140px;
      .event-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        width: 100%;
        font-size: 16px;
        .left {
          display: flex;
          align-items: center;
          color: #7b7b7b;
          img {
            width: 20px;
            margin-right: 5px;
          }

          .yellow {
            color: #f9ba48 !important;
            cursor: pointer;
          }
          .gray {
            color: #7b7b7b !important;
          }

          .title {
            // font-weight: 500;
            font-weight: 400;
            margin: 0 10px;
            display: inline-block;
            max-width: 800px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .right {
          color: #9b9b9b;
        }
      }
    }

    .city-wrapper {
      display: flex;
      margin-top: 57px;
      height: 387px;
      border-radius: 8px;
      background-color: white;
      .left {
        position: relative;
        width: 516px;
        overflow: hidden;
        height: inherit;
        text-align: left;
        cursor: pointer;

        .cover {
          position: absolute;
          z-index: 0;
          width: 516px;
          height: 387px;
          border-radius: 8px;
          object-fit: cover;
        }
      }
      .right {
        flex: 1;
        padding: 57px 45px 0px 45px;
        .city-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left-info {
            display: flex;
            align-items: center;
            .city-name {
              max-width: 180px;
              font-size: 40px;
              font-weight: 600;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              cursor: pointer;
            }
            .join-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 36px;
              line-height: 36px;
              margin-left: 18px;
              padding: 0px 15px;
              font-size: 16px;
              // font-weight: 500;
              font-weight: 400;
              color: white;
              background-color: #f9ba48;
              border-radius: 20px;
              cursor: pointer;
            }
            .joined {
              background-color: #f0f0f0;
              color: #7b7b7b;
            }
          }
          .owner-info {
            display: flex;
            align-items: center;
            font-size: 14px;
            .nickname {
              max-width: 100px;
              margin-left: 10px;
              color: #7b7b7b;
              font-weight: 500;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .date {
              color: #9b9b9b;
              margin-left: 12px;
            }
          }
        }
        .followed-users {
          margin-top: 54px;
          .pc-users {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
          }
          .mp-users {
            display: none;
          }
          .more-users {
            position: relative;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: #f0f0f0;
            color: #7b7b7b;
            font-size: 30px;
            &::after {
              content: "...";
              position: absolute;
              font-size: 26px;
              left: 50%;
              transform: translate(-50%, -15%);
            }
          }
          .total-count {
            font-size: 16px;
            // font-weight: 500;
            font-weight: 400;
            span {
              color: #f9ba48;
            }
          }
        }

        .city-indexes {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          align-items: center;
          margin-top: 23px;
          .index {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #7b7b7b;
            border: 1px solid #e6e6e6;
            border-radius: 20px;
            padding: 2px 10px;
            gap: 3px;
          }
        }
        .line {
          display: none;
        }
        .hide-in-pc {
          display: none;
        }
      }
    }
    .city-wrapper-reverse {
      flex-direction: row-reverse;
      .tag-wrapper {
        justify-content: end;
      }
    }

    .create-city-btn {
      display: flex;
      justify-content: center;
      width: 592px;
      margin: 80px auto 0;
      padding: 15px 0;
      font-size: 16px;
      // font-weight: 500;
      font-weight: 400;
      color: #7b7b7b;
      border: 1px solid #e0e0e0;
      border-radius: 40px;
      cursor: pointer;
      * {
        display: inline-block;
      }
      .text {
        color: #f9ba48;
      }
      .create-icon {
        position: relative;
        width: 24px;
        height: 24px;
        color: white;
        border-radius: 50%;
        background-color: #f9ba48;
        &::after {
          content: "+";
          position: absolute;
          left: 50%;
          transform: translate(-50%, -7%);
        }
      }
    }
  }
}

.city-tag-wrapper {
  display: flex;
  column-gap: 10px;
  position: relative;
  z-index: 1;
  padding: 20px;
  color: white;
  font-size: 16px;
  // font-weight: 500;
  font-weight: 400;
  .tag {
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
    align-items: center;
    padding: 2px 7px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.35);
    img {
      width: 20px;
    }
    .small {
      display: none;
    }
  }
}
.ant-progress-steps-item {
  width: 10px !important;
  height: 3px !important;
  border-radius: 30%;
}

@media screen and (max-width: 1175px) {
  .cities-container {
    margin-bottom: 25px;
    .small-container {
      width: 100%;
      padding: 0 15px;
      .create-city-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        .all-city-text {
          font-size: 14px;
          font-weight: 500;
          color: #4a4a4a;
        }
        .sm-create-city-btn {
          display: flex;
          column-gap: 3px;
          font-size: 12px;
          align-items: center;
          color: #7b7b7b;
        }
      }
      .event-swiper {
        margin: 15px 0 15px 0;
        height: 100px;
        .event-item {
          font-size: 12px;
          height: 20px;
          .left {
            img {
              margin-right: 0px;
              width: 15px;
            }
            .title {
              margin: 0 5px;
              max-width: 140px;
            }
            .nickname {
              max-width: 90px;
            }
          }
        }
      }
      .city-wrapper {
        margin-top: 12px;
        width: 100%;
        height: 120px;
        .left {
          width: 88px;
          .hide-in-mp {
            display: none;
          }
          .cover {
            width: 88px;
            height: 120px;
          }
        }
        .right {
          padding: 15px 15px 10px 15px;
          width: auto;
          .city-info {
            flex-wrap: wrap;
            .left-info {
              justify-content: space-between;
              width: 100%;
              .city-name {
                font-size: 16px;
                max-width: 150px;
              }
              .join-btn {
                height: 24px;
                line-height: 24px;
                margin-left: 9px;
                font-size: 11px;
                color: #f9ba48;
                border: 1px solid #f9ba48;
                background-color: white;
                padding: 0 10px;
              }
              .joined {
                color: #7b7b7b;
                border: none;
                background-color: #f0f0f0;
              }
            }
            .owner-info {
              display: none;
            }
          }
          .followed-users {
            margin-top: 10px;
            .pc-users {
              display: none;
            }
            .mp-users {
              display: flex;
              align-items: center;
              column-gap: 5px;
            }
            .more-users {
              width: 22px;
              height: 22px;
              font-size: 10px;
              &::after {
                font-size: 15px;
                left: 50%;
                transform: translate(-50%, -20%);
              }
            }
            .total-count {
              font-size: 12px;
              font-weight: 400;
              span {
                color: #f9ba48;
              }
            }
          }
          .city-indexes {
            display: none;
          }
          .line {
            display: block;
            width: 100%;
            height: 1px;
            background-color: #f0f0f0;
            margin: 9px 0;
          }
          .hide-in-pc {
            display: flex;
          }
        }
      }
      .city-wrapper-reverse {
        flex-direction: row;
      }
      .create-city-btn {
        display: none;
        width: 70%;
        padding: 5px;
        font-size: 12px;
        margin-top: 40px;
        .create-icon {
          width: 15px;
          height: 15px;
          &::after {
            left: 50%;
            transform: translate(-50%, -4%);
          }
        }
      }
    }
  }
  .city-tag-wrapper {
    font-size: 11px;
    flex-wrap: wrap;
    padding: 10px 5px;
    color: #e89b11;
    column-gap: 5px;
    padding: 0px;
    .tag {
      background-color: rgba(249, 186, 72, 0.1);
      border-radius: 4px;
      padding: 2px;
      column-gap: 0;
      img {
        width: 12px;
        margin-right: 5px;
      }
      .normal {
        display: none;
      }
      .small {
        display: block;
      }
    }
  }
  .ant-progress-steps-item {
    width: 7px !important;
    height: 2px !important;
    border-radius: 20%;
  }
}
