/**
 * wwads 广告自适应尺寸
 *
 * https://wwads.cn/help/publishers/change-banner-css
 */
.wwads-cn a.block{
  width: 100% !important;
  height: auto !important;
}


.promo-item {
  margin-bottom: 10px;

  &.promo-type-sidebar,
  &.promo-type-post {
    a {
      display: block;
    }

    img {
      width: 100%;
    }
  }

  &.promo-type-post {
    margin-top: 10px;
  }

}


/* Banner */
.promo-banner {
  margin-bottom: 10px;

  a {
    display: block;
  }

  img {
    width: 100%;
    height: 100px;
  }

  @media (min-width: @screen-md-min) {
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
}
