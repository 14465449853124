.sider-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;

  li {
    background: #F7F7F7;
    padding: 15px 24px;
  }

  li + li {
    margin-top: 6px;
  }

  li a {
    display: block;
  }
}
