body {
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
  color: #3d3d3d;
}

div,
img {
  box-sizing: border-box;
}

.wrapper {
  margin-top: -20px;

  ul {
    list-style: none;
  }

  ul li::before {
    content: "\2022";
    color: #ffbb34;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    margin-left: -1em;
  }

  .tag {
    width: 141px;
    height: 72px;
    background-color: #f9ba48;
    position: relative;
    padding-left: 11px;
    margin-top: 60px;
    font-size: 32px;
    font-weight: 500;
    line-height: 72px;
    font-weight: 600;

    &::before {
      content: "";
      position: absolute;
      left: 101px;
      border-style: solid;
      border-width: 0 40px 80px 0;
      border-color: transparent white transparent transparent;
    }
  }

  .title {
    text-align: center;

    * {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      width: 32px;
      height: 31px;
    }

    div {
      margin: 0 30px;
      font-size: 42px;
      font-weight: 600;
      color: #242424;
    }
  }

  .block1 {
    background-color: white;

    .nav {
      width: 1200px;
      margin: 0 auto;
      padding: 16px 0;
      background-color: white;

      * {
        display: inline-block;
        vertical-align: middle;
      }

      .nav-left {
        img {
          width: 147px;
          height: 47.7px;
        }

        .line {
          width: 1px;
          height: 36px;
          background: #343434;
          margin: 0 25px;
        }

        .text {
          font-size: 24px;
        }
      }

      .nav-right-pc {
        margin-left: 160px;
        font-size: 20px;

        span {
          padding: 0 20px;
          cursor: pointer;
        }
      }

      .nav-right-mb {
        display: none;
      }
    }

    .header {
      width: 100%;
      background-color: #f9ba48;
      padding: 37px;

      .content {
        width: 1200px;
        margin: 0 auto;

        .left {
          display: inline-block;
          vertical-align: middle;

          .text-pc {
            font-size: 68px;
          }

          .text-mb {
            display: none;
          }

          .text-en {
            font-size: 60px;
            font-weight: 500;
            margin-top: 14px;
          }

          .text-en-small {
            font-size: 40px;
            line-height: 40px;
          }

          .consult {
            width: 201px;
            height: 58px;
            line-height: 58px;
            font-size: 24px;
            font-weight: 500;
            color: white;
            border-radius: 10px;
            text-align: center;
            background-color: #3d3d3d;
            margin-top: 32px;
            cursor: pointer;
          }
        }

        .header-img {
          display: inline-block;
          width: 336px;
          vertical-align: middle;
          margin-left: 235px;
        }
      }
    }
  }

  .block2 {
    padding: 45px 0 58px 0;
    background-color: white;

    .item-w {
      display: flex;
      justify-content: space-around;
      width: 1200px;
      margin: 80px auto 0;

      .item {
        position: relative;
        width: 356px;
        font-size: 20px;
        text-align: center;
        padding: 70px 0 20px 0;
        border: 1px solid #f9ba48;
        border-radius: 10px;

        span {
          display: block;
        }

        img {
          position: absolute;
          width: 96px;
          left: 50%;
          top: -35%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .block3 {
    background-color: white;
    padding: 29px 0;
    .content {
      width: 1200px;
      margin: 0 auto;
      .panel {
        background-color: #f3f3f3 !important;
        padding-bottom: 24px;
        margin-top: 33px;
      }
      .remove {
        display: none !important;
      }
      .add {
        display: block;
      }
      ul {
        margin-bottom: 0px;
      }
      .detail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 156px;
        height: 51px;
        line-height: 51px;
        border-radius: 25px;
        border: 1px solid black;
        font-size: 20px;
        font-weight: 500;
        gap: 10px;
        margin-right: 30px;
        cursor: pointer;

        img {
          width: 11px;
          vertical-align: middle;
        }
      }
      .list-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 12px;
        .list-i {
          width: 500px;
        }
      }
      .course-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .course-title-left {
          * {
            display: inline-block;
            vertical-align: middle;
          }
        }

        .line {
          width: 10px;
          height: 73px;
          background-color: #f9ba48;
          margin-right: 40px;
        }

        .course-title-text {
          position: relative;
          font-size: 32px;
          font-weight: 500;
        }

        // .course-title-text-big-margin {
        //   margin-left: 40px;
        // }

        .days {
          position: relative;
          width: 88px;
          height: 44px;
          text-align: center;
          margin-left: 40px;
          img {
            width: 88px;
          }
          .icon-mp {
            display: none;
          }

          div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 26px;
            font-weight: 500;
            width: 100px;
          }
        }
        .arrow-down {
          transform: rotate(90deg);
        }
      }
      .course-time {
        padding-top: 20px;
        padding-left: 35px;
        border-top: 1px solid #fff;
        & span:first-child {
          font-size: 24px;
          font-weight: 500;
        }

        & span:last-child {
          font-size: 20px;
          padding-left: 36px;
        }
      }
      .course-core-text {
        font-size: 24px;
        font-weight: 500;
        padding-top: 14px;
        padding-left: 35px;
      }
    }

    .course-list-title {
      font-size: 20px;
      font-weight: 500;
      background-color: #f9ba48;
      width: 500px;
      height: 40px;
      line-height: 40px;
      padding: 0 26px;
      border-radius: 30px;
      margin-top: 16px;
    }
    .course-list-title-sm {
      font-size: 15px;
    }

    .course-list {
      font-size: 18px;
      width: 500px;

      li {
        margin-top: 13px;
      }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0 0 !important;
    }

    .ant-collapse-ghost
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding: 0 0 !important;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 0 !important;
    }
  }

  .block4 {
    background-color: #f9ba48;
    padding: 36px 0;

    .content {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      margin-top: 30px;
      align-items: center;

      .content-left {
        div {
          display: flex;
          margin: 12px 0;
          width: 730px;

          & img:first-child {
            display: none;
          }
        }

        img {
          width: 22px;
          height: 20px;
          margin: 6px 14px 0 0;
        }

        p {
          margin-bottom: 0;
          font-size: 20px;
        }
      }

      .content-right {
        width: 297px;
        height: 361px;
        margin-left: 150px;
      }
    }
  }

  .block5 {
    padding: 55px 0;
    background-color: white;

    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 1200px;
      margin: 0 auto;

      .item {
        position: relative;
        width: 574px;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
        border-width: 1px 1px 1px 5px;
        border-color: #f9ba48;
        border-style: solid;
        border-radius: 10px;
        margin-top: 46px;
        padding: 5px 34px;

        & span:first-of-type {
          font-size: 42px;
          font-weight: 500;
          padding-right: 10px;
        }

        & span:last-of-type {
          font-size: 24px;
          font-weight: 400;
        }

        p {
          font-size: 16px;
          color: #474747;
          text-align: justify;
        }
      }

      .arrow {
        position: absolute;
        width: 61px;
        height: 19px;
        top: 25px;
        right: 40px;
      }

      .p1 {
        display: none;
      }
    }
  }

  .block6 {
    padding: 50px 0;

    .content {
      width: 1200px;
      margin: 0 auto;

      .block {
        display: flex;
        justify-content: space-between;
        margin-top: 70px;

        .item {
          position: relative;
          width: 394px;
          height: 185px;
          background-color: white;
          border: 2px solid #f9ba48;
          border-radius: 10px;
          padding: 37px 12px 0 12px;

          & div:first-child {
            position: absolute;
            width: 181px;
            height: 54px;
            background-color: #f9ba48;
            border-radius: 163px;
            font-size: 26px;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
            left: 50%;
            top: -12%;
            transform: translateX(-50%);
          }

          p {
            font-size: 16px;
            margin-bottom: 0;

            & span:first-child {
              padding-right: 10px;
            }
          }
        }
      }

      .en {
        display: none;
      }

      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        div {
          width: 585px;
          border: 2px solid #f9ba48;
          border-radius: 10px;
          font-size: 26px;
          font-weight: 400;
          padding-left: 20px;
          margin-top: 30px;
          background-color: white;
          padding: 18px;
        }

        span {
          padding-right: 10px;
        }
      }

      .p6,
      .p7 {
        display: none;
      }
    }

    .p3 {
      display: block;
      width: 1200px;
      margin: 20px auto 0;
    }
  }

  .block7 {
    background-color: white;
    padding: 70px 0;

    .content {
      width: 1200px;
      margin: 23px auto 0;

      .about {
        width: 1200px;
        margin: 0 auto;
        font-size: 16px;
        line-height: 30px;
        text-align: justify;
        background-color: #f3f3f3;
        padding: 10px 30px;
      }

      .tag-big {
        width: 351px;

        &::before {
          left: 311px;
        }
      }

      .item-w {
        display: flex;
        gap: 30px;
        justify-content: space-between;
        margin-top: 27px;

        .item {
          .item-title {
            div {
              display: inline-block;
              vertical-align: middle;
            }

            .line {
              width: 7px;
              height: 48px;
              background-color: #f9ba48;
            }

            .item-title-text {
              font-size: 20px;
              font-weight: 500;
              width: 380px;
              margin-left: 10px;
            }
          }

          .item-content {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            ul {
              padding-left: 16px;

              li {
                margin-top: 10px;
              }
            }

            .p4 {
              width: 273px;
              height: 123px;
            }

            .p5 {
              width: 257px;
            }
          }
        }
      }

      .qa-item-w {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;
        gap: 37px;

        .item {
          width: 579px;
          background-color: #f3f3f3;
          border-radius: 10px;
          border-left: 2px solid #f9ba48;
          padding: 12px 10px 30px 10px;

          div {
            font-size: 20px;
            font-weight: 600;
            line-height: 33px;
          }

          p {
            font-size: 16px;
            margin-top: 10px;
            margin-bottom: 0px;
            line-height: 26px;
          }
        }
      }
    }
  }

  .block8 {
    background-color: #f9ba48;
    padding: 19px 0 43px 0;

    .content {
      width: 1200px;
      text-align: center;
      margin: 0 auto;

      .text1 {
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        margin-top: 22px;
      }

      .tag {
        display: none;
      }

      .item-w {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 30px;
        font-size: 19px;
        column-gap: 35px;
        row-gap: 20px;

        div {
          width: 576px;
          border-radius: 20px;
          border: 1px solid #000;
          line-height: 25px;
          padding: 7px 0;
        }

        & div:last-child {
          background-color: #3d3d3d;
          color: white;
        }
      }
    }
  }

  .block9 {
    background-color: #3d3d3d;
    text-align: center;
    padding: 30px 0;
    .title-text-mp {
      display: none;
    }

    div {
      color: white !important;
    }

    .text1 {
      font-size: 24px;
      font-weight: 500;
      margin-top: 15px;
    }

    .contact {
      width: 182px;
      margin-top: 30px;
    }
  }
}

.menu-w {
  opacity: 0;
  position: fixed;
  width: 100px;
  background-color: #474747;
  border-radius: 10px;
  padding: 30px 5px;
  top: 400px;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  img {
    width: 76px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10%;
  }

  .menu {
    a {
      display: inline-block;
      width: 90px;
      height: 34px;
      line-height: 34px;
      background-color: #f9ba48;
      text-align: center;
      border-radius: 20px;
      margin: 16px auto 0;
      color: #3d3d3d;
      cursor: pointer;

      &:hover {
        background-color: #ffd994;
      }
      &:active {
        background-color: #d99418;
      }
    }
  }
}

.show {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.hide {
  opacity: 0;
  transition: opacity 0.5s ease;
}

@media screen and (max-width: 1175px) {
  .wrapper {
    .tag {
      width: 108px;
      height: 30px;
      margin-top: 15px;
      font-size: 16px;
      line-height: 30px;
      text-align: left;

      &::before {
        left: 90px;
        top: -1px;
        border-width: 0 19px 36px 0;
        border-color: transparent #f3f3f3 transparent transparent;
      }
    }

    .title {
      div {
        font-size: 23px;
        margin: 0 10px;
        font-weight: 500;
      }

      img {
        width: 18px;
        height: 18.5px;
      }
    }

    .block1 {
      .nav {
        position: relative;
        width: 100%;
        padding: 27px 16px 14px 16px;

        .nav-left {
          img {
            width: 89px;
            height: 28px;
          }

          .line {
            height: 18px;
            margin: 10px;
          }

          .text {
            font-size: 14px;
          }
        }

        .nav-right-pc {
          display: none;
        }

        .nav-right-mb {
          width: 50px;
          display: inline-block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
        }
      }

      .header {
        padding: 20px 20px;

        .content {
          width: 100%;

          .left {
            .text-pc {
              display: none;
            }

            .text-mb {
              display: block;
              font-size: 42px;
              line-height: 46px;
              font-weight: 600;
              width: 210px;
            }

            .text-en {
              font-size: 20px;
              font-weight: 400;
              margin-top: 20px;
            }

            .text-en-small {
              font-size: 15px;
              line-height: 15px;
              font-weight: 400;
            }
          }

          .header-img {
            width: 105.5px;
            vertical-align: middle;
            margin-left: 12px;
            margin-top: 20px;
          }

          .consult {
            display: none;
          }
        }
      }
    }

    .block2 {
      padding: 30px 5px;
      background-color: #f3f3f3;

      .item-w {
        margin-top: 35px;
        width: 100%;

        .item {
          padding: 30px 5px 0 5px;
          width: 30%;
          height: 112.5px;
          font-size: 11px;
          text-align: justify;
          border: none;
          border-radius: 0%;
          background-color: white;

          span {
            display: inline;
          }

          img {
            width: 49px;
            height: 49px;
            top: -20%;
          }
        }
      }
    }

    .block3 {
      background-color: #f3f3f3 !important;
      padding: 0 0 15px 0;

      .content {
        margin-top: 15px;
        width: 100%;
        background-color: #f3f3f3 !important;
        .panel {
          padding: 15px;
          background-color: white !important;
          margin-top: 17px;
        }
        .ant-collapse {
          background-color: #f3f3f3 !important;
        }
        .remove {
          display: none;
        }
        .detail {
          width: 78px;
          height: 22px;
          line-height: 22px;
          border-radius: 18px;
          font-size: 10px;
          font-weight: 500;
          gap: 4px;
          margin-right: 0;
          img {
            width: 6px;
          }
        }

        .course-title {
          .line {
            width: 3.5px;
            height: 22px;
            margin-right: 7.5px;
          }

          .course-title-text {
            font-size: 18px;
            font-weight: 500;
          }

          .days {
            width: 34px;
            height: 20px;
            margin-left: 5px;
            .icon-mp {
              display: inline-block;
            }
            .icon-web {
              display: none;
            }

            img {
              width: 34px;
              margin-top: -7.5px;
            }

            .icon-big {
              width: 49px;
            }

            div {
              font-size: 14px;
              font-weight: 500;
              width: 34px;
            }
            .big {
              left: 70%;
            }
          }
        }

        .course-time {
          padding-top: 10px;
          padding-left: 0px;

          & span:first-child {
            font-size: 12px;
            font-weight: 600;
          }

          & span:last-child {
            font-size: 12px;
            padding-left: 10px;
          }
        }

        .course-core-text {
          font-size: 12px;
          font-weight: 600;
          padding-left: 0px;
          padding-top: 10px;
        }

        .course-list-title {
          font-size: 12px;
          width: 100%;
          height: 24px;
          line-height: 24px;
          padding: 0 14px;
          margin-top: 12px;
        }
        .course-list-title-sm {
          font-size: 11px;
        }

        .course-list {
          font-size: 11px;
          padding-left: 20px;
          width: 93%;

          li {
            margin-top: 9px;

            &::before {
              width: 10px;
            }
          }
        }

        .list-wrapper {
          padding: 0px;
          .list-i {
            width: 100%;
          }
        }
      }

      .uls {
        display: flex;
        width: 70%;
        justify-content: space-between;
      }
    }

    .block4 {
      padding: 15px 16px;
      .content {
        width: 100%;
        margin-top: 0px;

        .content-left {
          div {
            width: 100%;

            & img:first-child {
              display: block;
            }

            & img:last-of-type {
              display: none;
            }
          }

          img {
            width: 17px;
            height: 20px;
            margin: 0px 10px 0 0;
          }

          p {
            font-size: 12px;
          }
        }

        .content-right {
          display: none;
        }
      }
    }

    .block5 {
      padding: 15px 0;
      background-color: #f3f3f3;

      .content {
        width: 96%;
        margin: 0 auto;

        .item {
          width: 100%;
          box-shadow: none;
          background-color: white;
          padding: 6.6px 10px;
          margin-top: 15px;

          & span:first-of-type {
            font-size: 24px;
            font-weight: 500;
          }

          & span:last-of-type {
            font-size: 14px;
          }

          p {
            font-size: 11px;
          }
        }

        .arrow {
          display: none;
        }

        .p1 {
          display: block;
          width: 100%;
          margin: 20px auto 0;
        }
      }
    }

    .block6 {
      padding: 15px 0;

      .content {
        width: 100%;

        .block {
          width: 96%;
          margin: 28px auto 0;

          .item {
            width: 48%;
            height: 122px;
            padding: 13px 10px;

            & div:first-child {
              width: 85px;
              height: 22px;
              line-height: 22px;
              font-size: 13px;
              font-weight: 500;
              span {
                display: none;
              }
            }

            p {
              font-size: 10px;
              margin-bottom: 2px;

              & span:first-child {
                padding-right: 10px;
              }
            }
          }

          .item-pc {
            display: none;
          }
        }

        .en {
          display: block;
          width: 96%;
          border: 2px solid #f9ba48;
          border-radius: 10px;
          margin: 11px auto 0;
          background-color: white;
          padding: 11px 11px;

          div {
            display: inline-block;
            vertical-align: middle;
            .en-text {
              display: none;
            }
          }

          & div:first-child {
            width: 85px;
            height: 22px;
            line-height: 22px;
            font-size: 13px;
            font-weight: 500;
            background-color: #f9ba48;
            border-radius: 10px;
            text-align: center;
            margin-right: 16px;
          }

          p {
            font-size: 10px;
            margin-bottom: 5px;
          }
        }

        .list {
          div {
            width: 96%;
            margin: 11px auto 0;
            font-size: 13px;
            padding: 4px 16px;
            span {
              display: none;
            }
          }
        }

        .pictures {
          text-align: center;
          margin-top: 10px;

          img {
            display: block;
          }

          & img:first-child {
            width: 372px;
            height: 228px;
          }

          & img:last-child {
            width: 362px;
            height: 320px;
          }
        }

        .p6,
        .p7 {
          display: block;
          width: 100%;
          margin-top: 10px;
        }
      }

      .p3 {
        display: none;
      }
    }

    .block7 {
      background-color: inherit;
      padding: 8px 0;

      .content {
        width: 100%;
        margin-top: 0px;

        .about {
          width: 100%;
          margin: 0 auto;
          font-size: 11px;
          text-align: justify;
          padding: 3px 15px;
          line-height: 23px;
        }

        .tag {
          background-color: #f9ba48;
          margin-top: 14px;
        }

        .tag-big {
          width: 175px;

          &::before {
            left: 156px;
          }
        }

        .item-w {
          flex-wrap: wrap;
          gap: 15px;
          width: 97%;
          margin: 10px auto 0;

          .item {
            width: 100%;
            background-color: white;
            padding: 5px 0 20px 10px;

            .item-title {
              .line {
                width: 3px;
                height: 30px;
              }

              .item-title-text {
                font-size: 12px;
                font-weight: 500;
                width: 245px;
              }
            }

            .item-content {
              font-size: 11px;
              margin-top: 8px;

              ul {
                padding: 0 10px;
                li {
                  &::before {
                    width: 10px;
                  }
                }
              }

              .p4 {
                width: 165px;
                height: 75px;
              }

              .p5 {
                width: 165px;
                height: 95px;
              }
            }
          }
        }

        .qa-item-w {
          flex-wrap: wrap;
          width: 96%;
          margin: 14px auto 0;
          gap: 14px;

          .item {
            width: 100%;
            background-color: white;
            border: 1px solid #f9ba48;
            border-left: 4px solid #f9ba48;
            padding: 10px;

            div {
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
            }

            p {
              font-size: 11px;
              line-height: 20px;
              margin-top: 5px;
            }
          }
        }
      }
    }

    .block8 {
      padding-bottom: 15px;
      margin-top: 16px;

      .content {
        width: 100%;

        .text1 {
          width: 317px;
          font-size: 12px;
          font-weight: 500;
          margin: 15px auto 0;
        }

        .item-w {
          width: 92%;
          margin: 15px auto 0;
          flex-wrap: wrap;
          gap: 15px;
          font-size: 12px;

          div {
            width: 100%;
            line-height: 15px;
            padding: 5px 0;
          }
        }

        .tag {
          display: block;
          color: white;
          background-color: black;

          &::before {
            border-color: transparent #f9ba48 transparent transparent;
          }
        }
      }
    }

    .block9 {
      .title-text-mp {
        display: inline-block;
      }
      .title-text-pc {
        display: none;
      }
      .text1 {
        font-size: 12px;
        font-weight: 500;
      }

      .contact {
        width: 172px;
        margin-top: 14px;
      }
    }
  }
  .menu-w {
    display: none;
  }
}

// @media screen and (max-width: 820px) {}

// @media screen and (max-width: 768px) {}
