@import "~antd/dist/antd.less";

// ------- Colors ----------
@primary-color: #f9ba48;

// ------- Disabled states -------
@disabled-bg: #d8d8d8;

// ------- Buttons -------
@btn-disable-bg: #d8d8d8;
@btn-disable-color: #ffffff;
@btn-padding-base: 0 32px;
@btn-height-base: 44px;

// ------- Menus -----------
// @menu-highlight-color: #000000;

/* Form */
.ant-form-item-label > label::after {
  content: "";
}

/* Card */
.ant-card {
  border-radius: 4px;
  margin-bottom: 20px;
}

.ant-card-body {
  padding: 10px;

  @media (min-width: @screen-sm-min) {
    padding: 15px;
  }

  @media (min-width: @screen-md-min) {
    padding: 20px;
  }
}

.ant-card-bordered {
  border: none;
}

.ant-card-head::after {
  content: " ";
  background: #f9ba48;
  height: 3px;
  width: 72px;
}

/* PageHeader */
.ant-page-header-heading-sub-title {
  font-size: 12px;
}

.ant-btn-link .anticon {
  vertical-align: middle;
}

// Buttons

.ant-btn {
  font-size: 16px;
}

.ant-btn-sm {
  font-size: 12px;
}

.ant-btn.active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}

.ant-menu {
  font-size: 16px;
}

// Alerts
.ant-alert-message {
  color: #4a4a4a;
}

.ant-alert-warning {
  border: none;
}

.ant-back-top-content,
.ant-back-top-content:hover {
  background-color: #f9ba48;
}

.ant-dropdown-menu-item-active:hover {
  background: rgba(255, 211, 115, 0.1);
}

.ant-drawer-content-wrapper {
  width: 256px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 15px 20px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 6px;
}
.ant-collapse {
  border: none !important;
  background-color: white !important;
}
.ant-collapse > .ant-collapse-item {
  border: none !important;
  background-color: #fafafa !important;
  margin: 5px 0;
}
.ant-collapse-content {
  border: none !important;
}
