.ai-wrapper {
  margin-top: -20px;
  * {
    box-sizing: border-box;
  }
  background-color: white;
  .title-w {
    position: relative;
    width: 180px;
    height: 60px;
    margin: 0 auto;
    text-align: center;
    font-size: 32px;
    background: url("~/assets/ai/p4.png");
    background-size: cover;
    background-repeat: no-repeat;
    .title {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -3px;
    }
  }
  .block1 {
    width: 100%;
    position: relative;
    background: url("~/assets/fullstack/p1@3x.png");
    background-size: cover;
    background-repeat: no-repeat;
    .content {
      padding: 25px 0 23px 21px;
      position: relative;
      .left {
        .text {
          font-size: 26px;
          line-height: 40px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .text-en {
          font-size: 12px;
          font-weight: 500;
          color: #7f7f7f;
          position: relative;
          z-index: 2;
        }
        .learn-btn {
          cursor: pointer;
          width: 138px;
          height: 38px;
          line-height: 38px;
          border-radius: 19px;
          background-color: #3d3d3d;
          color: white;
          font-size: 16px;
          text-align: center;
          margin-top: 35px;
        }
      }
    }
    .p3m,
    .p3 {
      position: absolute;
      width: 173px;
      bottom: 0;
      right: 0;
    }
    .p3 {
      display: none;
    }
  }
  .block2 {
    .content {
      padding: 42px 15px 20px 15px;
      .list-content {
        position: relative;
        align-items: center;
        font-size: 14px;
        .list-w {
          margin-top: 15px;
          .item {
            display: flex;
            align-items: start;
            margin-top: 4px;
            .p5 {
              position: relative;
              margin-right: 2px;
              img {
                width: 31px;
                height: 27px;
              }
              div {
                position: absolute;
                top: 50%;
                left: 32%;
                transform: translateY(-50%);
              }
            }
            .text {
              line-height: 29px;
            }
          }
        }
        .p6 {
          display: none;
        }
      }
    }
  }
  .block3 {
    background-color: #fff8eb;
    .content {
      padding: 28px 15px;
    }
    .list-w {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        position: relative;
        border-radius: 10px;
        background-color: white;
        width: 100%;
        height: 90px;
        padding-left: 130px;
        padding-top: 15px;
        box-sizing: border-box;
        margin-top: 53px;
        img {
          position: absolute;
          width: 121px;
          top: -33px;
          left: 5px;
        }
        .line {
          width: 46px;
          height: 6px;
          background-color: #ffc658;
          border-radius: 3px;
        }
        .text {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          margin-top: 8px;
          text-align: left;
        }
      }
    }
  }
  .block4 {
    .ant-collapse {
      margin-top: 15px;
    }
    .ant-collapse > .ant-collapse-item,
    .ant-collapse-header,
    .ant-collapse-content-box {
      background-color: white;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0px 20px !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 20px;
    }
    .ant-collapse-header-text {
      border-top-left-radius: 23px;
      border-top-right-radius: 23px;
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
      margin-top: 10px;
    }
    .without-line .ant-collapse-header-text {
      border: none !important;
    }
    .content {
      padding: 36px 0;
      .ac-item-title {
        display: flex;
        width: 100%;
        height: 46px;
        border-radius: 23px;
        background-color: #f3f3f3;
        font-size: 20px;
        font-weight: 500;
        color: #3d3d3d;
        justify-content: space-between;
        padding-left: 18px;
        align-items: center;
        border: 1px solid #dbdbdb;
        img {
          width: 120px;
          margin-right: -10px;
          margin-top: 6px;
        }
      }

      .ac-item {
        padding: 6px 10px 8px 15px;
        background-color: white;
        font-size: 14px;
        color: #5d5d5d;
        border-radius: 0 0 10px 10px;
        border-left: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;
        .y-title {
          position: relative;
          color: #3d3d3d;
          font-size: 16px;
          padding-left: 15px;
          padding-top: 5px;
          margin-bottom: 10px;
          &::before {
            content: "";
            position: absolute;
            width: 5px;
            height: 42px;
            background-color: #f9ba48;
            top: 13%;
            transform: translateX(-50%);
            left: 3px;
          }
        }
        .y-title-sm {
          &::before {
            width: 5px;
            height: 20px;
            top: 8px;
          }
          margin-top: 15px;
        }
        .list-w {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-direction: column;
          p {
            margin-bottom: 0px !important;
            line-height: 27px;
          }
        }
      }
    }
  }
  .block7 {
    .content {
      padding: 10px 0 25px 0;
      .list-w {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 10px;
        width: 100%;
        row-gap: 36px;
        margin-top: 50px;
        .p10 {
          display: none;
        }

        .item {
          position: relative;
          width: 100%;
          border-radius: 10px;
          border: 1px solid #dbdbdb;
          padding: 30px 0 5px 15px;

          .name {
            position: absolute;
            background: url("~/assets/java/p32@3x.png");
            background-size: contain;
            width: 98px;
            height: 32px;
            font-size: 20px;
            font-weight: bold;
            line-height: 32px;
            padding-left: 15px;
            top: -15px;
            left: 10px;
          }
          .text {
            font-size: 12px;
            position: relative;
            padding-left: 3px;
            line-height: 30px;
            &::before {
              content: "";
              position: absolute;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              top: 13px;
              left: -5px;
              background-color: black;
            }
          }
          background: linear-gradient(to bottom, #fff2db, #fffefe);
        }
      }
    }
  }
  .block8 {
    background-color: #585858;
    color: white;
    .content {
      padding: 25px 20px 30px 20px;
      .list-w {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: end;
      }
      .item {
        .price {
          font-size: 32px;
          letter-spacing: 15px;
          position: relative;
          z-index: 1;
          padding-left: 20px;
          &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 35px;
            width: 122px;
            height: 12px;
            border-radius: 6px;
            background-color: #ffc65b;
            z-index: -1;
          }
        }
        .price-list {
          font-size: 16px;
          padding: 10px 0 0 10px;
          line-height: 30px;
        }
        img {
          width: 105px;
        }
        .contact-text {
          width: 110px;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          margin-top: 7px;
        }
      }
      .item-right {
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 1175px) {
  .block1 {
    .p3 {
      display: block;
      width: 526px !important;
      right: 30px !important;
    }
  }
}

@media screen and (min-width: 1175px) {
  .ai-wrapper {
    .content {
      width: 1200px;
      margin: 0 auto;
    }
    .title-w {
      font-size: 36px;
      .title {
        bottom: -5px;
      }
      img {
        width: 280px;
      }
    }
    .title-pc {
      width: 200px;
      background: url("~/assets/ai/p4-pc.png");
      background-size: cover;
      background-repeat: no-repeat;
      .title {
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-53.5%);
      }
    }

    .block1 {
      .content {
        padding: 52px 40px 72px 40px;
        .left {
          .text {
            font-size: 56px;
            line-height: 100px;
            font-weight: bold;
            margin-bottom: -5px;
            position: relative;
            z-index: 2;
          }
          .text-en {
            font-size: 26px;
            line-height: 31px;
          }
          .learn-btn {
            width: 253px;
            height: 68px;
            line-height: 68px;
            border-radius: 34px;
            background-color: #3d3d3d;
            text-align: center;
            font-size: 26px;
            color: white;
            margin-top: 50px;
          }
        }
      }
      .p3 {
        display: block;
      }
      .p3m {
        display: none !important;
      }
    }
    .block2 {
      .content {
        padding: 42px 20px 92px 20px;
        .list-content {
          font-size: 20px;
          .list-w {
            margin-top: 35px;
            .item {
              margin-top: 14px;
              .p5 {
                img {
                  width: 41px;
                  height: 37px;
                }
                div {
                  left: 32%;
                }
              }
            }
          }
          .p6 {
            position: absolute;
            display: block;
            top: -120px;
            right: 0px;
            width: 436px;
            height: 451px;
          }
        }
      }
    }
    .block3 {
      .content {
        padding: 37px 50px 66px 50px;
      }
      .list-w {
        display: flex;
        justify-content: space-between;
        padding: 0px;
        margin-top: 110px;

        .item {
          width: 344px;
          height: 244px;
          padding: 115px 30px 86px 30px;
          img {
            width: 186px;
            left: 50%;
            transform: translateX(-50%);
            top: -90px;
          }
          .line {
            margin: 0 auto;
          }
          .text {
            font-size: 20px;
            margin: 0 auto;
            line-height: 30px;
            margin-top: 21px;
          }
          .text-1 {
            text-align: center;
          }
        }
        .item-1 {
          padding: 115px 15px 86px 15px;
        }
      }
    }
    .block4 {
      .content {
        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
          padding: 0px 0px !important;
        }
        .ant-collapse-content > .ant-collapse-content-box {
          padding: 0 0px;
        }
        padding: 58px 0 25px 0;
        .ant-collapse {
          margin-top: 25px;
        }
        .ant-collapse-header-text {
          border-top-left-radius: 35px;
          border-top-right-radius: 35px;
          margin-top: 24px;
        }
        .ac-item-title {
          height: 71px;
          border-radius: 35px;
          background-color: #f3f3f3;
          font-size: 26px;
          padding-left: 34px;
          border: 1px solid #d8d8d8;
          img {
            width: 187px;
            margin-right: -23px;
            margin-top: 13px;
          }
        }
        .ac-item {
          padding: 10px 32px 26px 0;
          font-size: 20px;
          border-radius: 0 0 35px 35px;
          background-color: white;
          .y-title {
            font-size: 26px;
            padding-left: 30px;
            margin-bottom: 15px;
            margin-top: 20px;
            &::before {
              content: "";
              position: absolute;
              width: 5px;
              height: 25px;
              background-color: #f9ba48;
              top: 28%;
              transform: translateX(-50%);
              left: 20px;
            }
          }
          .y-title-sm {
            margin-top: 30px;
          }
          .list-w {
            flex-direction: row;
            .item {
              width: 520px;
              p {
                padding-left: 31px;
                margin-top: 15px;
              }
            }
            .pc-item {
              padding-left: 8px;
            }
          }

          p {
            margin-bottom: 6px !important;
            line-height: 33px;
          }
        }
      }
    }
    .block7 {
      .content {
        padding-top: 33px;
        .list-w {
          flex-wrap: nowrap;
          justify-content: space-around;
          align-items: center;
          margin-top: 35px;
          padding: 0px;
          .item {
            width: 520px;
            height: 300px;
            padding: 40px 0 0 25px;
            .name {
              font-size: 32px;
              width: 153px;
              height: 50px;
              line-height: 50px;
              padding-left: 30px;
              top: -26px;
              left: 15px;
            }
            .text {
              font-size: 18px;
              padding: 0px 15px 0 10px;
              line-height: 35px;
              &::before {
                width: 6px;
                height: 6px;
                top: 15px;
              }
            }
            .text-1 {
              line-height: 25px;
              &::before {
                width: 6px;
                height: 6px;
                top: 10px;
              }
            }
          }
          .p10 {
            display: block;
            width: 350px;
          }
        }
      }
    }
    .block8 {
      .content {
        padding: 35px 35px 63px 35px;
        .list-w {
          align-items: center;
          .item {
            .price {
              font-size: 36px;
              letter-spacing: 20px;
              &::before {
                top: 40px;
              }
            }
            .price-list {
              font-size: 26px;
              line-height: 45px;
            }
            img {
              width: 182px;
            }
            .contact-text {
              font-size: 26px;
              width: 250px;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}
