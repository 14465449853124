.container {
  max-width: 1180px;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;

  @media (min-width: @screen-sm-min) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (min-width: @screen-md-min) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.large-container {
  width: 100%;
  padding: 0;
}

.app-layout {
  margin-top: 20px;
  min-height: calc(100vh - 70px - 170px);

  &::after {
    clear: both;
  }

  @media (min-width: @screen-md-min) {
    flex-wrap: nowrap;
  }
}

.app-content {
  float: none;
  width: 100%;
  margin: 0 0 20px 0;
}

@media (min-width: @screen-md-min) {
  .app-layout.has-sider .app-content {
    float: left;
    width: calc(100% - 340px);
  }
}

.app-sider {
  float: none;

  @media (min-width: @screen-md-min) {
    float: right;
    width: 320px;
  }
}
