/* CKEditor */
.bf-content * {
  line-height: 1.3;
}

.bf-modal {
  z-index: 1000;
}

.public-DraftStyleDefault-block {
  margin-bottom: 0.5em;
}

.rich-content {
  overflow: hidden;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  & > :first-child {
    margin-top: 0;
  }

  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 24px;
    border-bottom: 2px solid #eee;
    padding-bottom: 5px;
  }

  h2 {
    font-size: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    margin-bottom: 0.6em;
  }

  blockquote {
    padding: 5px 10px;
    margin: 0 0 1em 0;
    font-size: 1em;
    border-left: 5px solid #eee;

    & > :first-child {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  pre {
    padding: 5px 10px;
    font-size: 1em;
    background: #eee;
    white-space: pre-wrap;
  }

  img,
  video,
  audio {
    max-width: 100%;
    // height: auto;
  }

  hr {
    border: 1px solid #eee;
  }

  figcaption {
    font-size: 0.8em;
    color: #aaa;
  }

  ul,
  ol {
    margin: 0 0 1em 0;
    padding: 0 0 0 2em;
  }

  ul > ul,
  ul > ol,
  ol > ul,
  ol > ul {
    margin-bottom: 0;
  }

  .secret_note {
    background-color: #fff8ee;
    padding: 3px 8px;
  }
}
