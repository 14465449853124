.fs-wrapper {
  margin-top: -20px;
  * {
    box-sizing: border-box;
  }
  background-color: white;
  .title-w {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    img {
      position: absolute;
      width: 114px;
      left: 50%;
      transform: translateX(-50%);
      top: 25px;
    }
    .title-white {
      color: white;
    }
  }
  .block1 {
    width: 100%;
    background: url("~/assets/fullstack/p1@3x.png");
    background-size: cover;
    background-repeat: no-repeat;
    .content {
      padding: 20px 0 20px 21px;
      position: relative;
      .text {
        font-size: 28px;
        line-height: 40px;
        font-weight: bold;
      }
      .text-en {
        font-size: 20px;
        line-height: 20px;
        font-weight: lighter;
      }
      .text-en-1 {
        margin-top: 16px;
      }
      .pc-text {
        display: none;
      }

      img {
        position: absolute;
        width: 182px;
        bottom: 0;
        right: 12px;
      }
      .pcp4 {
        display: none;
      }
    }
  }
  .block2 {
    .content {
      padding-top: 26px;
      .list-w-pc {
        display: none;
      }
      .list-w-mb {
        .list-w {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 64px 21px 0 21px;

          .item {
            display: flex;
            gap: 14px;
            padding-top: 4px;
            align-items: cneter;
            img {
              width: 52px;
              height: 44px;
            }
            p {
              width: 100px;
              font-size: 12px;
            }
          }
        }
        .mb-item {
          display: flex;
          gap: 14px;
          align-items: cneter;
          padding: 4px 22px 0 22px;
          img {
            width: 52px;
            height: 44px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
  .block3 {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0px 0px !important;
    }
    .ant-collapse-ghost
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding: 0 0 !important;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 0 !important;
    }
    .ant-collapse > .ant-collapse-item {
      background-color: #f3f3f3 !important;
      border-radius: 18px;
    }
    .content {
      padding: 27px 17px 30px 17px;
      .text {
        font-size: 14px;
        color: #3d3d3d;
        padding-top: 52px;
      }

      .ac-item-title {
        display: flex;
        width: 100%;
        height: 36px;
        border-radius: 18px;
        background-color: #f3f3f3;
        font-size: 14px;
        font-weight: 500;
        color: #3d3d3d;
        justify-content: space-between;
        padding-left: 18px;
        align-items: center;
        border: 1px solid #dbdbdb;
        img {
          width: 95px;
          // height: 36px;
          margin-right: -10px;
          margin-top: 6px;
        }
      }
      .ac-item {
        padding: 6px 10px 8px 17px;
        background-color: #f6f6f6;
        font-size: 14px;
        color: #5d5d5d;
        border-radius: 0 0 10px 10px;
        .item {
          .title {
            position: relative;
            color: #3d3d3d;
            font-weight: 600;
            padding-left: 15px;
            &::before {
              content: "";
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #f9ba48;
              top: 42%;
              transform: translateX(-50%);
              left: 0px;
            }
          }
          .detail {
            padding-left: 38px;
          }

          p {
            padding-left: 15px;
          }

          .line {
            border: none;
            border-top: 1px dotted #a4a4a4;
            height: 1px;
            width: 100%;
            margin: 10px 0;
          }
        }
        p {
          margin-bottom: 0px !important;
          line-height: 33px;
        }
      }
      .panel {
        margin-top: 17px;
      }
    }
  }
  .block4 {
    background: url("~/assets/fullstack/p14@3x.png");
    background-size: cover;
    .content {
      padding-top: 9px;
      .wrapper {
        padding: 0 0 25px 14px;
        margin-top: 61px;
        .left {
          .item {
            display: flex;
            align-items: center;
            margin-top: 13px;
            font-size: 12px;
            color: #3d3d3d;
            .icon {
              width: 17px;
              height: 19px;
              background: url("~/assets/fullstack/p13@3x.png");
              background-size: cover;
              font-size: 12px;
              color: white;
              text-align: center;
            }
            p {
              margin-bottom: 0px;
              margin-left: 7px;
            }
          }
        }
        .right {
          display: none;
        }
      }
    }
  }
  .block5 {
    .content {
      padding-top: 17px;
      .list-w {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        padding: 83px 15px 22px 15px;
        .item {
          width: 100%;
          position: relative;
          .name {
            background: url("~/assets/fullstack/p16@3x.png");
            background-size: cover;
            width: 99px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            position: absolute;
            font-size: 20px;
            font-weight: bold;
            color: #3d3d3d;
            top: -20px;
            left: 20px;
          }
          .desc {
            padding: 28px 20px 10px 14px;
            border-radius: 10px;
            background-color: #f3f3f3;
            font-size: 12px;
            color: #3d3d3d;
            box-shadow: 0 0 10px 5px #dbdbdb;
            text-align: justify;
          }
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
  .block6 {
    background-color: #5d5d5d;
    padding: 18px 28px 34px 28px;
    .content {
      text-align: center;
      .price {
        display: none;
      }
      .list-w {
        display: flex;
        justify-content: space-between;
        padding-top: 68px;
        .item {
          .top {
            font-size: 16px;
            color: #5d5d5d;
            width: 138px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 20px;
            background-color: #f9ba48;
          }
          .top-after {
            color: white;
            background-color: #ff7402;
          }
          .bottom {
            font-size: 32px;
            color: white;
            font-weight: 500;
            width: 138px;
            text-align: center;
            span {
              font-weight: 200;
            }
          }
        }
      }
      .contact {
        width: 145px;
        margin-top: 45px;
      }
      .contact-text {
        width: 156px;
        font-size: 14px;
        color: white;
        margin: 10px auto;
      }
      .number-with-line {
        position: relative;
      }
      .number-with-line::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-47%);
        bottom: 50%;
        width: 80%;
        height: 2px;
        background-color: white;
      }
    }
  }
}
@media screen and (min-width: 1175px) {
  .fs-wrapper {
    .content {
      width: 1200px;
      margin: 0 auto;
    }
    .title-w {
      font-size: 42px;
      img {
        width: 199px;
        height: 15px;
        top: 50px;
      }
      .title-white {
        color: white;
      }
    }

    .block1 {
      .content {
        padding: 58px 0 42px 0px;
        .text {
          font-size: 80px;
          line-height: 100px;
          font-weight: bold;
        }
        .text-en {
          font-size: 40px;
          font-weight: lighter;
          line-height: 57px;
        }
        .text-en-1 {
          font-size: 60px;
          font-weight: bold;
          margin-top: 60px;
        }
        .pc-text {
          display: block;
        }
        .mp-text {
          display: none;
        }
        img {
          width: 540px;
          right: 40px;
        }
        .p4 {
          display: none;
        }
        .pcp4 {
          display: block;
        }
      }
    }
    .block2 {
      .content {
        padding: 35px 0px 120px 0;
        .list-w-mb {
          display: none;
        }
        .list-w-pc {
          padding-top: 140px;
          display: block;
          .list-w {
            width: 1140px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .item {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 24px;
              img {
                width: 120px;
              }
              p {
                width: 188px;
                font-size: 20px;
                text-align: left;
              }
            }
          }
          .list-w-b {
            width: 660px;
            margin: -150px auto;
          }
        }
      }
    }
    .block3 {
      .ant-collapse {
        margin-top: 58px;
      }
      .ant-collapse-header-text {
        background-color: white;
        border-radius: 35px;
      }

      .ant-collapse > .ant-collapse-item {
        background-color: white !important;
        box-shadow: 0px 7px 10px #d8d8d8;
        border-radius: 35px !important;
      }
      .ant-collapse-content {
        border-radius: 0 0 35px 35px;
      }
      .content {
        padding: 102px 0 67px 0;
        .text {
          width: 770px;
          margin: 0 auto;
          text-align: center;
          font-size: 24px;
          padding-top: 99px;
        }
        .ac-item-title {
          height: 71px;
          border-radius: 35px;
          background-color: #f3f3f3;
          font-size: 28px;
          padding-left: 34px;
          border: 1px solid #d8d8d8;
          img {
            width: 187px;
            margin-right: -23px;
            margin-top: 13px;
          }
        }
        .ac-item {
          padding: 26px 32px;
          font-size: 20px;
          border-radius: 0 0 35px 35px;
          background-color: white;
          .item {
            width: 520px;
            .title {
              position: relative;
              color: #3d3d3d;
              font-weight: 600;
              padding-left: 30px;
              padding-bottom: 10px;
              &::before {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #f9ba48;
                top: 25%;
                left: 15px;
              }
            }
            .detail {
              padding-left: 65px;
            }
            p {
              padding-left: 30px;
            }

            .line {
              display: none;
            }
          }

          p {
            margin-bottom: 4px !important;
            line-height: 33px;
          }
        }
        .ac-item-pc {
          padding: 15px 15px 25px 15px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 35px;
          width: 1135px;
        }
        .without-shadow {
          box-shadow: none !important;
        }
        .panel {
          margin-top: 32px;
        }
      }
    }
    .block4 {
      .content {
        padding-top: 30px;
        .wrapper {
          display: flex;
          gap: 34px;
          padding: 32px 0px 36px 79px;
          margin-top: 58px;
          .left {
            .item {
              display: flex;
              align-items: center;
              margin-top: 17px;
              font-size: 20px;
              .icon {
                width: 21px;
                height: 25.6px;
                font-size: 12px;
                line-height: 25.6px;
              }
              p {
                margin-bottom: 0px;
                margin-left: 7px;
              }
            }
          }
          .right {
            display: block;
            width: 434px;
          }
        }
      }
    }
    .block5 {
      .content {
        padding-top: 52px;
        .list-w {
          gap: 30px;
          row-gap: 80px;
          padding: 138px 0px 57px 0px;
          justify-content: space-between;
          .item {
            width: 48%;
            position: relative;
            .name {
              width: 196px;
              height: 77px;
              line-height: 77px;
              font-size: 42px;
              top: -45px;
            }
            .desc {
              padding: 44px 20px 10px 36px;
              font-size: 20px;
              box-shadow: 0 0 10px 5px #dbdbdb;
              text-align: left;
              width: 560px;
              height: 344px;
              line-height: 40px;
            }
          }
        }
      }
    }
    .block6 {
      padding: 28px 28px 52px 28px;
      .title-w {
        display: none;
      }
      .content {
        text-align: left;
        .pc {
          display: flex;
          justify-content: space-between;
          padding: 0 40px;
          width: 1090px;
          .left {
            width: 369px;
            display: inline-block;
            .price {
              display: block;
              font-size: 42px;
              font-weight: bold;
              color: white;
            }
            .list-w {
              gap: 24px;
              padding-top: 20px;
              .item {
                .top {
                  font-size: 20px;
                  width: 176px;
                }
                .bottom {
                  font-size: 36px;
                  width: 176px;
                }
              }
            }
          }

          .wrapper {
            display: inline-flex;
            align-items: center;
            gap: 17px;
            .contact {
              margin-top: 45px;
            }
            .contact-text {
              margin-top: 45px;
              font-size: 20px;
              width: 222px;
            }
          }
        }
        .number-with-line::before {
          width: 70%;
        }
      }
    }
  }
}
