@font-face {
  font-family: "DINPro";
  src: url("../assets/web3/DINPro.ttf");
  font-weight: 500;
  font-style: normal;
}

.web3 {
  font-family: "DINPro", "Helvetica";
  background-position-y: 800px;

  .btn {
    padding: 9px 16px;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 500;
    background: linear-gradient(90deg, #ffba3a 0%, #eec06a 100%);
    color: white;
    border: 2px solid #ffba3b;
    letter-spacing: 0.8px;
    cursor: pointer;
  }

  .btn.trans {
    padding: 9px 10px;
    background: transparent;
  }

  .title {
    display: inline-flex;
    padding-right: 65px;
    margin-bottom: 74px;

    h1 {
      font-size: 56px;
      font-weight: 500;
      line-height: 74px;
    }

    h2 {
      font-size: 42px;
      font-weight: 400;
      color: #4b4b4b;
      margin-top: 15px;
      margin-left: 24px;
    }

    border-bottom: 2px solid #ffb939;
  }

  .header {
    display: flex;
    background-color: #000;
    height: 75px;
    padding: 15px 135px;
    border-bottom: 1px solid #ffba3b;

    ul>li {
      list-style: none;
    }

    font-size: 16px;

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      margin-left: 32px;
    }

    ul>li {
      padding: 0 24px;
      height: 100%;
    }

    ul>li>a {
      opacity: 0.8;
      color: #f7f7f7;
      line-height: 48px;
      text-transform: uppercase;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    ul>li>a.active {
      opacity: 1;
      color: #ffba3b;
    }
  }

  .section-1 {
    background-size: cover;
    background-position-y: -75px;
    padding: 100px;
    padding-left: 25%;
    color: white;

    h1 {
      color: white;
      font-weight: 500;
      font-size: 60px;
      margin-bottom: 0;

      span {
        font-family: "DINPro", "Helvetica";
        font-weight: 600;
        color: #ffba3b;
      }
    }

    .p1 {
      margin-top: 10px;
      font-size: 16px;
      line-height: 21px;
    }

    .p2 {
      width: 650px;
      font-weight: 400;
      font-size: 14px;
      color: #f7f7f7;
      line-height: 25px;
    }

    .buttons {
      display: flex;
      margin-top: 40px;
    }

    .socials {
      margin-top: 30px;

      img {
        margin-right: 30px;
      }
    }
  }

  .section-2 {
    display: flex;
    background-size: cover;
    height: 176px;
    padding: 0 130px;
    justify-content: space-around;

    .stats {
      margin-top: 26px;
      color: #f7f7f7;
      text-align: center;

      .num {
        font-weight: 900;
        font-size: 50px;
        line-height: 64px;
      }

      .name,
      .name-en {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
      }
    }
  }

  .section-3 {
    position: relative;
    padding: 110px 138px;
    border-bottom: 1px solid #ffb939;

    .logo-bg {
      position: absolute;
      top: -20px;
      right: 20px;
      height: 201px;
    }

    .post {
      display: block;

      background-color: #ffffff;
      margin-bottom: 24px;
      color: #333333;
      padding: 22px 24px;
      font-size: 22px;
      font-weight: 500;
      transition: none;
    }

    .post:hover {
      color: #ffffff;
      background-color: #ffba3a;
    }

    .more {
      margin: 0 auto;
      margin-top: 44px;
      margin-bottom: 128px;
      width: 211px;
      text-align: center;
    }

    .desc {
      margin-bottom: 60px;

      p {
        font-size: 28px;
        line-height: 36px;
        margin: 0;
      }
    }

    .services {
      display: grid;
      grid-template-columns: 50% 50%;
      row-gap: 80px;
      column-gap: 40px;
      margin-bottom: 150px;
    }

    .service {
      display: flex;
      width: 510px;
      font-size: 22px;
      line-height: 24px;

      img {
        width: 130px;
        height: 130px;
        margin-right: 50px;
      }

      .t1 {
        font-weight: 500;
      }

      .t2 {
        margin-top: 5px;
        font-weight: 400;
      }

      .t3,
      .t4 {
        font-size: 18px;
      }

      .t3 {
        margin-top: 20px;
      }

      .t4 {
        margin-top: 10px;
      }
    }

    .partner {
      margin: 10px 30px;
      height: 125px;
    }
  }

  .section-4 {
    padding: 72px 0 66px 0;
    text-align: center;
    color: #333333;

    p {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      margin: 0px;
      padding: 0px;
    }

    a:visited {
      color: inherit;
    }

    .s {
      margin: 0 15px;
      margin-top: 26px;
    }
  }

  @media screen and (max-width: 768px) {
    .title {
      display: inline-flex;
      padding-right: 15px;
      margin-bottom: 15px;

      h1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
      }

      h2 {
        font-size: 16px;
        margin-top: 5px;
        margin-left: 10px;
      }

      border-bottom: 2px solid #ffb939;
    }

    .header {
      padding: 15px 15px;

      .mb-hidden {
        display: none;
      }
    }

    .section-1 {
      padding: 20px;

      h1 {
        font-size: 50px;

        #web3-typewriter {
          display: block;
        }
      }

      .p2 {
        width: auto;
      }

      .btn {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: inherit;
      }

      .buttons {
        margin-top: 20px;
      }

      .socials {
        margin-top: 20px;

        .mb-hidden {
          display: none;
        }
      }

      .logo {
        width: 100px;
      }
    }

    .section-2 {
      padding: 0 15px;

      .stats {
        margin-top: 24px;
        color: #f7f7f7;
        text-align: center;

        .num {
          font-weight: 900;
          font-size: 40px;
          line-height: 64px;
        }

        .name,
        .name-en {
          font-weight: 500;
          font-size: 14px;
          line-height: 27px;
        }
      }
    }

    .section-3 {
      padding: 15px;
      padding-top: 40px;

      .post {
        font-size: 16px;
        padding: 12px 14px;
        margin-bottom: 12px;
      }

      .more {
        margin-top: 22px;
        margin-bottom: 40px;
      }

      .desc {
        margin-bottom: 20px;

        p {
          font-size: 18px;
          line-height: 28px;
          margin: 0;
        }
      }

      .services {
        grid-template-columns: 100%;
        row-gap: 0px;
        column-gap: 0px;
        margin-bottom: 40px;
      }

      .logo-bg {
        top: -10px;
        right: 0px;
        height: 60px;
      }

      .service {
        display: flex;
        width: auto;
        font-size: 18px;
        line-height: 29px;
        margin-bottom: 20px;

        img {
          width: 80px;
          height: 80px;
          margin-right: 20px;
        }

        .t2 {
          margin-top: 5px;
        }

        .t3,
        .t4 {
          font-size: 16px;
        }

        .t3 {
          margin-top: 6px;
        }

        .t4 {
          margin-top: 4px;
        }
      }

      .partner {
        height: auto;
        width: 33%;
      }
    }
  }
}