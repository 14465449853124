.app-header {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(123, 123, 123, 0.05);

  .inner {
    height: 70px;
    display: flex;
    align-items: middle;
    padding: 0 10px;
  }
}

.app-brand {
  flex: 0 0 215px;
}

.app-menu-desktop {
  flex: 1;
  display: none;

  .app-menu-left {
    border: 0;
    width: 500px;

    .ant-menu-item,
    .ant-menu-submenu {
      cursor: default;
      height: 70px;
      top: 0;
      margin-top: 0;
      margin-bottom: 0;
      border-bottom: 0;
      display: inline-block;
      padding: 18px 16px;
      margin: 0px 4px;
      font-size: 18px;
      line-height: 33px;
      text-decoration: none;
      color: #7b7b7b;
      z-index: 100;
      top: -10px;

      &::after {
        border: none !important;
      }

      a {
        position: relative;
        top: 10px;
      }
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-selected {
      background-color: @primary-color;
      border-radius: 0 0 4px 4px;

      a {
        color: #fff;
      }
    }
  }

  .app-menu-right {
    border: 0;
    text-align: right;

    .ant-menu-item {
      height: 70px;
      top: 0;
      margin-top: 0;
      margin-bottom: 0;
      border-bottom: 0;
      display: inline-block;
      padding: 18px 22px;
      font-size: 18px;
      line-height: 33px;
      text-decoration: none;
      color: #7b7b7b;

      &::after {
        border: none !important;
      }
    }

    .ant-menu-submenu {
      top: 0;
      border: 0;
      margin-top: 0;
      padding: 0 5px;

      &::after {
        border: none !important;
      }
    }

    .ant-menu-submenu-title {
      height: 70px;
      line-height: 70px;
    }

    .app-menu-search {
      padding: 3px 0px;
    }
  }
}

.app-menu-mobile {
  flex: 1;

  .ant-menu {
    border: none;
  }

  .ant-menu-item-selected::after {
    display: none;
  }
}

.ant-drawer {
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0;
  }
}

@media (min-width: @screen-lg-min) and (max-width: 1160px) {
  .app-menu-search {
    display: none !important;
  }
}

@media (min-width: @screen-lg-min) {
  .app-menu-desktop {
    display: block;
  }

  .app-menu-mobile {
    display: none;
  }
}

.app-menu-search {
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 40px !important;
  }
}

.camp-menu {
  .ant-menu-vertical {
    background-color: rgba(123, 119, 109, 0.8) !important;
    transform: translate(-18.3%, 7%);
  }
  .ant-menu-vertical > .ant-menu-item {
    height: 58px;
    line-height: 22px;
    text-align: center;
    font-size: 12px;
    color: white;
    margin: 0;
  }
  .ant-menu-vertical.ant-menu-sub {
    min-width: 86px;
  }
  .ant-menu-vertical .ant-menu-item {
    padding: 0;
  }
  .ant-menu-item-only-child {
    padding: 0px;
  }
  .ant-menu-vertical a:not(.ant-btn):visited,
  .ant-menu-vertical a:not(.ant-btn):link {
    color: white;
  }
  .ant-menu-vertical a:not(.ant-btn):hover {
    color: #f9ba48;
  }
  .ant-menu-vertical .ant-menu-item-only-child:not(:first-child) {
    border-top: 1px solid rgba(217, 217, 217, 0.5);
    padding-top: 8px;
  }
  .ant-menu-vertical .ant-menu-item-only-child:first-child {
    padding-top: 8px;
    margin-bottom: 0px;
  }
}
.camp-menu-item {
  height: 58px !important;
  line-height: 22px !important;
  .single-line {
    height: 40px;
    line-height: 40px;
  }
}
.app-avatar-dropdown-menu-trigger {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  .signin-btn {
    color: #f9ba48;
    border: 1px solid #f9ba48;
    border-radius: 4px;
  }
  .signup-btn {
    border-radius: 4px;
  }
}

.app-avatar-dropdown-menu-mask {
  position: fixed;
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 0;
  top: 70px;
  left: 0;
  z-index: 100;
  .app-avatar-dropdown-menu {
    opacity: 0;
    visibility: hidden;
    background-color: white;
    font-size: 14px;
    color: #7f7f7f;
    padding: 10px 25px 40px 25px;
    transition: all 0.3s ease-in-out;
    .app-avatar-dropdown-menu-me {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      a,
      div {
        width: 100px;
        text-align: center;
        &:hover {
          border: 1px solid #f9ba48;
          border-radius: 5px;
        }
      }
    }
    .app-avatar-dropdown-menu-others {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 30px;
    }
  }

  .app-avatar-dropdown-menu.show {
    opacity: 1;
    visibility: visible;
  }
}
.app-avatar-dropdown-menu-mask.show {
  height: 100%;
}

.app-mobile-nav {
  .ant-menu {
    font-size: 0.8rem;
    background-color: inherit;
    margin-top: -18px;
  }
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    bottom: 10px;
  }
}
