@import "./mixins/retina";

body {
  margin: 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFangSC;
  font-weight: 400;
  color: #4a4a4a;
  background: #f7f7f7;
}

.img-gray {
  filter: grayscale(100%);
}

.float-clear {
  clear: both;
  content: " ";
}

// .btn {
//   border-radius: 4px;
//   color: #ffffff;
//   line-height: 22px;
//   padding: 11px;
//   border: none;
// }

.btn-primary:hover {
  background: rgba(255, 211, 115, 1);
}

.btn-primary:active {
  background: rgba(212, 150, 51, 1);
}

.btn-primary,
.btn-primary[disabled] {
  background: rgba(249, 186, 72, 1);
}

.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  box-shadow: none;
}

.btn-block {
  display: block;
  width: 100%;
}

.hidden {
  display: none;
}

/* Text size */

.text-lg {
  font-size: 18px;
}

.text-md {
  font-size: 16px;
}

.text-sm {
  font-size: 12px;
}

/* Alignments */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.text-break {
  word-break: break-all;
}

.text-middle {
  vertical-align: middle;
}

.text-bottom {
  vertical-align: baseline;
}

/* Text modification */
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

/* Text Colors */

.text-primary {
  color: #f9ba48;
}

a:not(.ant-btn).text-primary:link {
  color: #f9ba48;
}

a:not(.ant-btn).text-primary:visited {
  color: #f9ba48;
}

a:not(.ant-btn).text-primary:hover {
  color: #f9ba48;
}

a:not(.ant-btn).text-primary:active,
.text-primary.active {
  color: #f9ba48;
}

.text-secondary {
  color: #9b9b9b;
}

a.text-secondary:link {
  color: #9b9b9b;
}

a.text-secondary:visited {
  color: #9b9b9b;
}

a.text-secondary:hover {
  color: #9b9b9b;
}

a.text-secondary:active,
.text-secondary.active {
  color: #9b9b9b;
}

// ---- Spacings ----

@spaces: 0 2 4 5 8 10 15 20 30 50 100;

each(@spaces, {
  .my-@{value} {
    margin-top: ~"@{value}px";
    margin-bottom: ~"@{value}px";
  }

  .mx-@{value} {
    margin-left: ~"@{value}px";
    margin-right: ~"@{value}px";
  }

  .px-@{value} {
    padding-left: ~"@{value}px";
    padding-right: ~"@{value}px";
  }

  .py-@{value} {
    padding-top: ~"@{value}px";
    padding-bottom: ~"@{value}px";
  }

  .mr-@{value} {
    margin-right: ~"@{value}px";
  }

  .ml-@{value} {
    margin-left: ~"@{value}px";
  }

  .mt-@{value} {
    margin-top: ~"@{value}px";
  }

  .mb-@{value} {
    margin-bottom: ~"@{value}px";
  }
  .gap-@{value}{
    gap: ~"@{value}px";
  }
})

/* Float */
.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

/* Position */
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}

/* Size */
.height-auto {
  height: auto;
}

/* Debug */
pre.debug {
  border: 1px solid #eee;
  background: #efefef;
  padding: 10px;
  max-height: 300px;
}

/* Links */
a:not(.ant-btn):link {
  color: #4a4a4a;
}

a:not(.ant-btn):visited {
  color: #7b7b7b;
}

a:not(.ant-btn):hover {
  color: #fbb42f;
}

a:not(.ant-btn):active,
a:not(.ant-btn).active {
  color: #d49633;
}

/* Forms */
.form-actions {
  display: flex;
}
.form-actions button {
  margin-right: 20px;
}

/* Menus */
.navs-post .ant-menu {
  font-size: 20px;
}

.modal-body .ant-menu {
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}

/* Sidebar */
.ant-layout-content {
  min-width: 320px;
}

.ant-layout-sider {
  background: transparent;
  margin-left: 20px;
}

.ant-layout-sider-collapsed {
  margin-left: 0;
}

/* Rich content */
.rich {
  img {
    max-width: 100%;
  }
}

/* Quote content */
.quote-content {
  blockquote {
    border-left: 5px solid #eeeeee;
    padding: 5px 10px;
    margin: 0;

    p {
      margin-bottom: 0;
    }

    p + p {
      margin-top: 10px;
    }
  }
}

.meta-info {
  font-size: 12px;
  line-height: 1.8;
  color: #9b9b9b;

  & > span + span::before {
    display: inline;
    content: " · ";
    color: #9b9b9b;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.list-item {
  border-bottom: 1px solid #f7f7f7;
  padding-top: 20px;
  padding-bottom: 20px;
}

.featured {
  font-size: 12px;
  color: #d49633;
  border: 1px solid @primary-color;
  padding: 0 3px;
  margin-left: 10px;
  border-radius: 2px;
}

.page-title {
  box-sizing: border-box;
  display: block;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-weight: 400;
}

.actions > span + span {
  margin-left: 15px;
}

/* badge */
.badge {
  display: inline-block;
  background-color: #f9ba48;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
  font-size: 12px;
}

.badge10 {
  width: 26px;
  border-radius: 10px;
}

.badge99 {
  width: 40px;
  border-radius: 10px;
}

.gender-icon span {
  vertical-align: middle;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-top {
  align-items: top;
}

.align-items-center {
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

@sizes: 10 11 12 13 14 16 17 18 19 20 32;
each(@sizes, {
  .font-@{value} {
    font-size: ~"@{value}px"
  }
});

@weights: 100 200 300 400 500 600 700 800 900;
each(@weights, {
  .font-weight@{value} {
    font-size: ~"@{value}px"
  }
});

@widths: 50 60 70 80 90 100 full;
each(@widths, {
  .w-@{value} {
    width: ~"@{value}px"
  }
});

.prompt-message {
  color: #51b52e;
  font-size: 14px;
}
.prompt-message-gray {
  color: #999;
  font-size: 14px;
}

.swiper-pagination-bullet-active {
  background-color: #f9ba48 !important;
}

.swiper-pagination {
  top: 80px !important;
}

.ant-card-head-title {
  font-weight: 400;
}

.w-full {
  width: 100%;
}
.theme-color {
  color: @primary-color;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}
