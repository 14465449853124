.ReactModal__Overlay {
  background-color: rgba(74, 74, 74, 0.75);
  overflow-y: auto;
  z-index: 1000;
}

.ReactModal__Content {
  width: auto;
  left: 5px !important;
  right: 5px !important;

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    margin-top: -10px;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-body {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .modal-body .modal-logo {
    display: block;
    margin: 20px auto;
    height: 38px;
  }

  .modal-body .navs {
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    list-style: none;
    display: table;
    table-layout: fixed;
    width: 100%;
    text-align: center;
    overflow: hidden;
    border-bottom: 2px solid #d8d8d8;
  }

  .modal-body .navs li {
    display: table-cell;
  }

  .modal-body .navs li a {
    display: block;
    text-decoration: none;
    color: #9b9b9b;
    font-size: 16px;
  }

  .modal-body .navs li a span {
    display: block;
    padding: 18px 18px;
    line-height: 25px;
  }

  .modal-body .navs li.active a {
    color: #000000;
  }

  .modal-body .navs li.active a::after {
    display: block;
    line-height: 0;
    content: " ";
    width: 40%;
    margin: 0 auto;
    border: 2px solid #f9ba48;
  }

  .modal-body .ant-form-item {
    margin-bottom: 20px;
  }

  .modal-body .ant-input {
    padding: 9px 16px;
    font-size: 16px;
    border-radius: 2px;
  }

  .modal-body .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
  }

  .modal-body .ant-input-affix-wrapper {
    padding: 0;
  }

  .modal-body .ant-input-prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    color: rgba(0, 0, 0, 0.65);
    z-index: 1;
  }

  .modal-body .ant-menu {
    justify-content: center;
  }
}
.newjob-modal {
  position: absolute;
  width: 89%;
  height: 562px;
  margin: 40px auto;
  overflow: hidden;
  overflow-y: scroll;
  color: #1e1d1d;
  padding: 20px 10px 10px 10px !important;
  inset: unset !important;
  border-radius: 12px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -55%);

  .show-on-pc {
    display: none;
  }
  .modal-body {
    margin: 0;
  }
  .header {
    .modal-title {
      font-size: 24px;
      font-weight: 900;
      padding: 10px 0;
    }
    .tip {
      font-size: 12px;
      font-weight: 400;
      width: 72%;
      margin: 0 auto;
    }
    .ant-alert {
      display: none;
      font-size: 12px;
      font-weight: 400;
      width: 388px;
      margin: 6px auto;
      background-color: #fef6da;
      border-radius: 12px;
      .ant-alert-message {
        color: #e34614;
      }
      .ant-alert-icon {
        margin-right: 0;
      }
    }
  }
  .ant-alert {
    display: none;
  }
  .newjob-body {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    row-gap: 10px;
    .item {
      width: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 12px;
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
      }
      .ant-collapse-item {
        margin: 0;
        border-radius: 12px;
        background-color: white !important;
      }
      .ant-collapse {
        border-radius: 12px;
      }

      .ant-collapse-header {
        border-radius: 12px;
        padding: 27px 25px !important;
        background: linear-gradient(to bottom, #e0fda1, #f0ffd0);
        .title-w {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          .title {
            font-size: 20px;
            font-weight: 600;
          }
          .arrow {
            width: 10px;
            height: 10px;
            border-left: 2px solid black;
            border-bottom: 2px solid black;
            transform: rotate(-45deg);
            margin-top: -10px;
          }
        }
        .sub-title {
          font-size: 12px;
          margin-top: 7px;
          .anticon {
            color: #f9ba48;
            margin-right: 5px;
          }
        }
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          .title-w {
            .arrow {
              transform: rotate(135deg);
              margin-top: 0px;
            }
          }
        }
      }
      .header {
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 28px 0;
        .title {
          font-size: 20px;
          font-weight: 600;
        }
        .sub-title {
          font-size: 12px;
          font-weight: 400;
          margin-top: 7px;
        }
        .anticon {
          color: #f9ba48;
          margin-right: 10px;
        }
      }
      .list {
        display: flex;
        row-gap: 35px;
        flex-direction: column;
        padding: 0px 10px;
        background-color: white;
        .list-i {
          display: flex;
          align-items: center;
          font-size: 14px;
          column-gap: 15px;
          font-weight: 400;
          img {
            width: 32px;
            height: 32px;
          }
          .text1 {
            font-size: 14px;
          }
          .text2 {
            font-size: 12px;
            color: #787777;
            margin-top: 5px;
            span {
              color: #f9ba48;
              text-decoration: underline;
              padding: 0px 2px;
              cursor: pointer;
            }
          }
          .text3 {
            font-size: 16px;
            font-weight: 600;
          }
        }
        .bullet-points {
          font-size: 14px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          div {
            position: relative;
            &::before {
              position: absolute;
              content: "";
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #1e1d1d;
              left: 15px;
              text-decoration: underline;
              padding: 0px 2px;
              top: 40%;
            }
          }
        }
        .list-i-mt {
          margin-top: 15px;
        }
      }
      .list3 {
        row-gap: 15px;
        text-align: center;
        padding: 10px 0px;
        width: 280px;
        margin: 0 auto;
        text-align: center;
        .list-i {
          justify-content: center;
        }
      }
      .btn {
        width: 84%;
        height: 46px;
        line-height: 46px;
        font-size: 16px;
        font-weight: 500;
        margin: 0 auto;
        text-align: center;
        border-radius: 12px;
        cursor: pointer;
      }
    }
    .item1 {
      .btn {
        background-color: #dffda1;
        margin-top: 18px;
      }
    }
    .item2 {
      .ant-collapse-header {
        background: linear-gradient(to bottom, #ffee95, #fff7cf);
      }
      .btn {
        background-color: #ffee95;
        margin-top: 12px;
      }
    }
    .item3 {
      .ant-collapse-header {
        background: linear-gradient(to bottom, #ffbca7, #ffe1d7);
      }
      .btn {
        background-color: #ffbca7;
        margin-top: 10px;
      }
      .line {
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        margin-top: 15px;
      }
      .services {
        display: flex;
        flex-wrap: nowrap;
        column-gap: 40px;
        margin-top: 15px;
        margin-left: 20px;
        div {
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            left: -15px;
            top: 40%;
            background-color: black;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  .ReactModal__Content {
    width: 500px;

    .modal-body {
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 40px;
    }
  }
  .newjob-modal {
    color: #1e1d1d;
    width: 1320px;
    height: 750px;
    padding: 0 !important;
    overflow-y: hidden;
    position: absolute;
    border-radius: 12px;
    transform: translate(-50%, -50%);

    .show-on-pc {
      display: block;
    }
    .show-on-mobile {
      display: none;
    }
    .header {
      .modal-title {
        font-size: 32px;
      }
      .ant-alert {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        width: 388px;
        margin: 20px auto;
        background-color: #fef6da;
        border-radius: 12px;
        .ant-alert-message {
          color: #e34614;
        }
        .ant-alert-icon {
          margin-right: 0;
        }
      }
      .tip {
        display: none;
      }
    }

    .newjob-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 25px;
      .item {
        width: 32%;
        border: 1px solid #e5e5e5;
        border-radius: 12px;
        padding-bottom: 0px;
        .header {
          text-align: center;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          padding: 28px 0;
          .title {
            font-size: 20px;
            font-weight: 600;
          }
          .sub-title {
            font-size: 12px;
            font-weight: 400;
            margin-top: 7px;
          }
          .anticon {
            color: #f9ba48;
            margin-right: 10px;
          }
        }
        .list {
          display: flex;
          row-gap: 25px;
          flex-direction: column;
          padding: 0px 30px;
          .list-i {
            display: flex;
            align-items: center;
            font-size: 14px;
            column-gap: 15px;
            font-weight: 400;
            img {
              width: 40px;
              height: 40px;
            }
            .text1 {
              font-size: 14px;
              span {
                color: #f9ba48;
                text-decoration: underline;
                padding: 0px 2px;
                cursor: pointer;
              }
            }
            .text2 {
              font-size: 12px;
              color: #787777;
              margin-top: 5px;
            }
            .text3 {
              font-size: 16px;
              font-weight: 600;
            }
          }
          .bullet-points {
            font-size: 16px;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            div {
              position: relative;
              &::before {
                position: absolute;
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #1e1d1d;
                left: 0px;
                text-decoration: underline;
                padding: 0px 2px;
                top: 40%;
              }
            }
          }
          .list-i-mt {
            margin-top: 55px;
          }
        }
        .list3 {
          row-gap: 15px;
          text-align: center;
          padding: 38px 0px;
          width: 280px;
          margin: 0 auto;
          text-align: center;
          .list-i {
            justify-content: center;
          }
        }
        .btn {
          width: 84%;
          height: 46px;
          line-height: 46px;
          font-size: 16px;
          font-weight: 500;
          margin: 0 auto;
          text-align: center;
          border-radius: 12px;
          cursor: pointer;
        }
      }
      .item1 {
        .header {
          background: linear-gradient(to bottom, #e0fda1, #f0ffd0);
        }
        .btn {
          background-color: #dffda1;
          margin-top: 45px;
        }
      }
      .item2 {
        .header {
          background: linear-gradient(to bottom, #ffee95, #fff7cf);
        }
        .btn {
          background-color: #ffee95;
          margin-top: 33px;
        }
      }
      .item3 {
        .header {
          background: linear-gradient(to bottom, #ffbca7, #ffe1d7);
        }
        .btn {
          background-color: #ffbca7;
          margin-top: 80px;
        }
      }
    }
  }

  // .newjob-modal {
  //   position: absolute;
  //   top: 80px;
  //   width: 920px;

  //   .modal-body {
  //     margin-left: 0px;
  //     margin-right: 0px;
  //     margin-bottom: 0px;
  //   }
  //   .newjob-body {
  //     display: flex;
  //     .item {
  //       color: #9b9b9b;
  //       line-height: 26px;
  //       width: 47.5%;
  //       border: 1px solid #f9ba48;
  //       padding: 20px 10px;
  //       margin: 2.5%;

  //       ul {
  //         min-height: 340px;
  //         // height: 100%;
  //       }

  //       .btn {
  //         margin-top: 20px;
  //         // position: absolute;
  //         // left: 0px;
  //         // bottom: 0px;
  //       }
  //     }

  //     .item-1 {
  //       margin-right: 1.25%;
  //       ul {
  //         padding-top: 20px;
  //       }
  //     }
  //     .item-2 {
  //       margin-left: 1.25%;
  //     }
  //   }
  // }
}

.wechat-unbind-modal {
  p {
    font-size: 16px;
  }
}

.wechat-unbind-modal__footer {
  display: flex;

  button {
    &:first-child {
      margin-right: 20px;
    }
  }
}
