.radio-gender.ant-radio-group-solid {
  display: flex;
  justify-content: space-between;

  .ant-radio-button-wrapper {
    flex: 0 47%;
    text-align: center;
    border: 0;
    background: #F7F7F7;
    color: #4A4A4A;
    height: 44px;

    &:before {
      display: none;
    }

    .ant-radio-button + span {
      display: block;
      line-height: 44px;
    }

    svg {
      vertical-align: middle;
      fill: #4A4A4A;
    }

    &-checked:not(.ant-radio-button-wrapper-disabled) {
      background: #F9BA48;
      color: #FFFFFF;
      box-shadow: none;

      svg {
        fill: #FFFFFF;
      }
    }
  }
}

