.ant-form-item-label {
  white-space: normal;
}

.modal-profile-edit {
  overflow-y: auto;

  .modal-body {
    overflow-y: auto;
    margin: 20px 40px;
  }

  .ant-row {
    display: block;
  }

  .ant-form-item-label {
    display: block;
    text-align: left;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 10px;
    padding: 0 !important;
    line-height: 40px;

    label {
      border-bottom: 3px solid @primary-color;
      display: inline-block;
      height: 100%;
    }

    .ant-form-item-required::before {
      display: none;
    }

    .ant-form-item-required::after {
      display: inline-block;
      margin-left: 4px;
      margin-right: 0;
      vertical-align: sub;
      color: #f5222d;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}