.bn-wrapper {
  /* 定义滚动条的宽度、颜色和背景 */
  // -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    height: 5px; /* 宽度 */
    border-radius: 3px;
  }

  /* 定义滚动条的滑块颜色 */
  ::-webkit-scrollbar-thumb {
    background-color: #ffc65b; /* 滑块颜色 */
  }

  /* 定义滚动条轨道的背景颜色 */
  ::-webkit-scrollbar-track {
    background-color: #ebebeb; /* 轨道背景颜色 */
  }
  margin-top: -20px;
  * {
    box-sizing: border-box;
  }
  background-color: white;
  .title-w {
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    color: #3d3d3d;
    .title {
      position: relative;
      width: 240px;
      margin: 0 auto;
      .circle-big {
        width: 20px;
        height: 20px;
        position: absolute;
      }
      .circle-small {
        width: 13px;
        height: 13px;
        position: absolute;
      }
    }
    .line {
      width: 75px;
      height: 6px;
      border-radius: 3px;
      background-color: #ffc65b;
      margin: 5px auto 0;
    }
  }
  .block1 {
    width: 100%;
    background: url("~/assets/business/p1@3x.png");
    background-size: cover;
    background-repeat: no-repeat;
    .content {
      padding: 20px 0 24px 38px;
      position: relative;
      .text {
        font-size: 21px;
        line-height: 26px;
        font-weight: bold;
      }
      img {
        position: absolute;
        width: 220px;
        bottom: 0;
        right: 15px;
      }
      .learn-btn {
        width: 92px;
        height: 28px;
        line-height: 28px;
        border-radius: 14px;
        background-color: #3d3d3d;
        margin-top: 10px;
        font-size: 10px;
        color: white;
        text-align: center;
        cursor: pointer;
      }
      .p2 {
        display: none;
      }
    }
  }
  .block2 {
    .content {
      padding: 32px 20px 0 20px;
      .title-w {
        .title {
          .circle-big {
            left: 25px;
          }
          .circle-small {
            bottom: 3px;
            right: -1px;
          }
        }
        .title-pc {
          display: none;
        }
      }
      .sub-title-mb {
        width: 251px;
        height: 26px;
        line-height: 26px;
        background-color: #ffc65b;
        border-radius: 13px;
        font-size: 12px;
        color: #3d3d3d;
        text-align: center;
        margin: 20px auto 0;
      }

      .text-content-wrapper {
        display: flex;
        align-items: start;
        justify-content: space-between;
        margin-top: 22px;
        .sub-title-pc {
          display: none;
        }
        .text-content {
          width: 186px;
          font-size: 10px;
          line-height: 18px;
          color: #3d3d3d;
        }
        .illustration {
          width: 151px;
        }
      }
    }
  }
  .block3 {
    .content {
      padding: 30px 12px 20px 12px;
      .title-w {
        .title {
          width: 325px;
          .circle-big {
            top: -5px;
            left: -8px;
          }
          .circle-small {
            bottom: 0px;
            right: -5px;
          }
        }
      }
      .sub-title {
        font-size: 14px;
        color: #3d3d3d;
        text-align: center;
        margin-top: 10px;
      }
      .text-content-w {
        margin-top: 13px;
        .text-content {
          .item {
            display: flex;
            align-items: center;
            font-size: 11px;
            column-gap: 5px;
            margin-top: 5px;
            div:first-child {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background-color: #ffc658;
              font-size: 9px;
              text-align: center;
            }
            .custom {
              width: 330px;
            }
          }
        }
        .illustration {
          display: none;
        }
      }
    }
  }
  .block4 {
    background-color: #ffc65b;
    padding: 30px 4px 20px 4px;
    .content {
      .title-w {
        .title {
          .circle-big {
            top: -5px;
            left: -15px;
          }
          .circle-small {
            bottom: 0;
          }
        }
        .sub-title {
          margin-top: 5px;
          text-align: center;
          font-size: 14px;
          color: #3d3d3d;
        }
        .line {
          background-color: rgba(255, 249, 238, 0.5);
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 13px;
        row-gap: 13px;
        margin-top: 25px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 110px;
          border-radius: 5px;
          background-color: white;
          padding: 15px 0;
          .icon {
            width: 39px;
            height: 39px;
            line-height: 39px;
            text-align: center;
            background-color: #ffc65b;
            border-radius: 50%;
            img {
              width: 21px;
              height: 21px;
            }
          }
          .text {
            font-size: 13px;
            font-weight: bold;
            margin-top: 10px;
          }
          .text1,
          .text2 {
            font-size: 10px;
          }
          .text1 {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .block5 {
    .content {
      padding: 50px 15px 0 15px;
      .title-w {
        .title {
          width: 200px;
          .circle-big {
            top: -5px;
            left: -5px;
          }
          .circle-small {
            bottom: 0;
            right: 0px;
          }
        }
        .line {
          margin-top: 15px;
        }
      }
      .text-content {
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 198, 91, 0.1);
        border-radius: 5px;
        margin: 15px auto 0;
        padding: 10px 10px 10px 20px;
        row-gap: 15px;
        div {
          position: relative;
          font-size: 12px;
          color: #3d3d3d;
          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #ffc65b;
            top: 16%;
            left: -10px;
          }
        }
        .single {
          &::before {
            top: 35%;
          }
        }
      }
      .sub-title {
        width: 170px;
        height: 26px;
        line-height: 26px;
        border-radius: 13px;
        background-color: #ffc65b;
        margin: 25px auto 0;
        font-size: 12px;
        color: #3d3d3d;
        text-align: center;
      }
      .graphic-content-wrapper {
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
        column-gap: 3px;
        overflow-x: scroll;
        padding: 10px 0;
        .column {
          width: 88px;
          border-radius: 5px;
          text-align: center;
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          .b1 {
            background-color: white;
            border: 1px solid #d8d8d8;
            border-radius: 5px;
            padding: 15px 0;
            .text1 {
              font-size: 14px;
              color: #90c985;
              font-weight: bold;
            }
            .text2 {
              width: 75px;
              height: 24px;
              line-height: 24px;
              border-radius: 12px;
              background-color: #90c985;
              font-size: 12px;
              font-weight: bold;
              color: white;
              margin: 5px auto 0;
            }
            .text3 {
              font-size: 9px;
              line-height: 18px;
              color: #90c985;
              border-top: 1px dotted #d8d8d8;
              border-bottom: 1px dotted #d8d8d8;
              margin-top: 10px;
            }
            .text4 {
              font-size: 9px;
              line-height: 18px;
              color: #90c985;
              border-bottom: 1px dotted #d8d8d8;
            }
            .text5 {
              font-size: 7px;
              width: 81px;
              height: 100px;
              margin: 5px auto 0;
              text-align: justify;
              padding: 0 3px;
            }
          }
          .b2,
          .b3,
          .b4,
          .b5 {
            font-size: 9px;
            color: #3d3d3d;
          }
          .b6 {
            font-size: 7px;
            color: #3d3d3d;
            text-align: justify;
            padding: 3px 2px;
          }
          .b2 {
            display: flex;
            justify-content: space-between;
            div {
              width: 40px;
              height: 16px;
              line-height: 16px;
              border-radius: 3px;
              border: 1px solid #cacaca;
              &:last-child {
                text-align: right;
              }
            }
          }
          .b3,
          .b4,
          .b5,
          .b6 {
            border: 1px solid #cacaca;
            border-radius: 3px;
          }
        }
        .column-0 {
          .b1 {
            border: none;
            .text3,
            .text4,
            .text5 {
              font-size: 9px;
              color: #3d3d3d;
              text-align: right;
              padding-right: 5px;
            }
          }
          .b3 {
            border: none;
            text-align: right;
            padding: 2px 5px 1px 0;
          }
          .b6 {
            border: none;
            padding: 2px 0 3px 0;
            text-align: right;
            padding-right: 5px;
            font-size: 9px;
            line-height: 22px;
          }
        }
        .column-2 {
          .b1 {
            .text1 {
              color: #839add;
            }
            .text2 {
              background-color: #839add;
            }
            .text3 {
              margin-top: 22px;
              color: #839add;
            }
            .text4 {
              color: #839add;
            }
          }
          .b2 {
            text-align: left;
          }
        }
        .column-3 {
          .b1 {
            .text1 {
              color: #918edd;
            }
            .text2 {
              background-color: #918edd;
            }
            .text3 {
              margin-top: 36px;
              color: #918edd;
            }
            .text4 {
              color: #918edd;
            }
          }
        }
        .column-4 {
          .b1 {
            .text1 {
              color: #f3b257;
            }
            .text2 {
              background-color: #f3b257;
            }
            .text3 {
              margin-top: 48px;
              color: #f3b257;
            }
            .text4 {
              color: #f3b257;
            }
          }
        }
        .double {
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          .top {
            display: flex;
            flex-direction: row;
            column-gap: 3px;
            align-items: flex-end;
            .column-5 {
              .b1 {
                .text1 {
                  color: #e39b75;
                }
                .text2 {
                  background-color: #e39b75;
                }
                .text3 {
                  margin-top: 60px;
                  color: #e39b75;
                }
                .text4 {
                  color: #e39b75;
                }
              }
            }
            .column-6 {
              .b1 {
                .text1 {
                  color: #df7c75;
                }
                .text2 {
                  background-color: #df7c75;
                }
                .text3 {
                  margin-top: 72px;
                  color: #df7c75;
                }
                .text4 {
                  color: #df7c75;
                }
              }
            }
          }
          .bottom {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            div {
              font-size: 9px;
              color: #3d3d3d;
              border: 1px solid #cacaca;
              text-align: center;
              border-radius: 3px;
            }
            .b3 {
              font-size: 7px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
  .block6 {
    .content {
      padding: 30px 20px 30px 20px;
      .title-w {
        .title {
          width: 290px;
          .circle-big {
            top: -5px;
            left: -5px;
          }
          .circle-small {
            bottom: 0px;
          }
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 15px;
        .item {
          display: flex;
          flex-direction: row;
          justify-content: left;
          column-gap: 20px;
          align-items: center;
          background-color: #fff9ee;
          border: 1px solid #f3b257;
          border-radius: 5px;
          font-size: 12px;
          padding: 10px 10px 10px 10px;
          .img1 {
            width: 74px;
            height: 63px;
            margin-left: -3px;
          }
          .img2 {
            width: 71px;
            height: 63px;
          }
          .img3 {
            width: 64px;
            height: 63px;
          }
        }
      }
    }
  }
  .block7 {
    background-color: #ffc65b;
    padding: 30px 14px 20px 14px;
    .content {
      .title-w {
        margin-bottom: 18px;
        .title {
          width: 350px;
          .circle-big {
            top: -5px;
            left: 15px;
          }
          .circle-small {
            bottom: 5px;
          }
        }
        .line {
          margin-top: 10px;
          background-color: rgba(255, 249, 238, 0.5);
        }
      }
      .text-content {
        font-size: 11px;
        color: #3d3d3d;
        margin-top: 10px;
        text-align: left;
        padding: 0 10px;
      }
      .list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 12px;
        row-gap: 17px;
        margin-top: 20px;
        .item {
          width: 165px;
          background-color: white;
          border-radius: 5px;
          text-align: center;
          color: #3d3d3d;
          padding: 13px 10px 17px 10px;
          .name {
            font-size: 18px;
            font-weight: bold;
          }
          .line {
            width: 34px;
            height: 4px;
            background-color: #f3b257;
            border-radius: 2px;
            margin: 0 auto;
          }
          .sub-list {
            display: flex;
            flex-direction: column;
            row-gap: 2px;
            align-items: flex-start;
            padding: 14px 5px 0 14px;
            .sub-item {
              text-align: left;
              font-size: 11px;
              position: relative;
              .dot {
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 4px;
                background-color: #ffc65b;
                top: 5px;
                left: -8px;
              }
            }
          }
        }
      }
    }
  }
  .block8 {
    .content {
      padding: 33px 12px 0 12px;
      .title-w {
        .title {
          width: 100px;
          .circle-big {
            top: -5px;
            left: -5px;
          }
          .circle-small {
            bottom: 0;
          }
        }
      }
      .list {
        display: flex;
        justify-content: center;
        column-gap: 15px;
        flex-direction: row;
        margin-top: 20px;
        .item {
          width: 168px;
          background-color: #fff9ee;
          border: 1px solid #ffc65b;
          border-radius: 5px;
          position: relative;
          padding: 0 5px 10px 5px;

          .title {
            position: absolute;
            width: 150px;
            height: 30px;
            line-height: 30px;
            font-size: 13px;
            background-image: url("../assets/business/p14@3x.png");
            background-size: contain;
            background-repeat: no-repeat;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
          }
          .b1 {
            padding-top: 46px;
            font-size: 9px;
            div {
              display: inline-block;
              vertical-align: middle;
            }
            .left {
              font-weight: bold;
            }
            .right {
              width: 100px;
              border-radius: 10px;
              text-align: center;
              padding: 3px 10px;
              background-color: #ffc65b;
              margin-left: 20px;
            }
            .right2 {
              width: 80px;
              text-align: center;
              padding: 3px 0px 3px 0px;
              margin-left: 30px;
            }
          }
          .b-d {
            padding-top: 39px;
          }
          .b2 {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            font-size: 9px;
            justify-content: space-between;
            margin-top: 12px;
            .left {
              font-weight: bold;
            }
            .right {
              width: 105px;
              display: flex;
              flex-direction: column;
              row-gap: 5px;
              div {
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background-color: #ffc65b;
                  top: 5px;
                  left: -8px;
                }
              }
            }
          }
          .b2-d {
            margin-top: 7px;
          }
          .b3,
          .b4 {
            font-size: 9px;

            div {
              display: inline-block;
              vertical-align: middle;
            }
            .left {
              font-weight: bold;
            }
            .right {
              margin-left: 15px;
            }
          }
          .b3 {
            margin-top: 10px;
          }
          .b4 {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .block9 {
    .content {
      padding: 35px 0px 28px 0px;
      .title-w {
        .title {
          width: 370px;
          .circle-big {
            left: -3px;
          }
          .circle-small {
            bottom: 0;
            right: 10px;
          }
        }
        .line {
          margin-top: 9px;
        }
      }
      .wechat-pics {
        margin-top: 17px;
        padding: 0 15px 14px 15px;
        background-color: white;
        overflow: scroll;
        img {
          width: 762px;
          height: 308px;
        }
      }
    }
  }
  .block10 {
    background-color: #585858;
    padding: 22px 0 20px 0;
    text-align: center;
    color: white;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .line {
      width: 80px;
      height: 6px;
      border-radius: 3px;
      margin: 5px auto 0;
      background-color: #ffc65b;
    }
    .contact-text {
      font-size: 13px;
      margin-top: 15px;
    }
    img {
      width: 100px;
      height: 96px;
      margin-top: 18px;
    }
  }
}
@media screen and (min-width: 1175px) {
  .bn-wrapper {
    .content {
      width: 1200px;
      margin: 0 auto;
    }
    .title-w {
      font-size: 36px;
      .title {
        width: 760px;
        margin: 0 auto;
        .circle-big {
          width: 40px;
          height: 40px;
        }
        .circle-small {
          width: 17px;
          height: 17px;
        }
      }

      .line {
        width: 169px;
        height: 12px;
        border-radius: 6px;
        margin: 15px auto 0;
      }
    }

    .block1 {
      .content {
        padding: 78px 50px 75px 50px;

        .text {
          font-size: 72px;
          line-height: 100px;
          font-weight: bold;
        }
        .text-en {
          font-size: 40px;
          font-weight: lighter;
          line-height: 57px;
        }

        img {
          right: 70px;
        }
        .learn-btn {
          width: 302px;
          height: 90px;
          line-height: 90px;
          border-radius: 45px;
          margin-top: 57px;
          font-size: 36px;
        }
        .p2 {
          display: block;
          width: 656px;
        }
        .mbp2 {
          display: none;
        }
      }
    }
    .block2 {
      .content {
        padding: 72px 0;
        .title-w {
          .title {
            .circle-big {
              left: -10px;
              bottom: 25px;
            }
            .circle-small {
              bottom: 5px;
            }
          }
          .title-pc {
            display: block;
          }
          .title-mb {
            display: none;
          }
        }
        .sub-title-mb {
          display: none;
        }
        .text-content-wrapper {
          flex-direction: row-reverse;
          align-items: center;
          justify-content: center;
          column-gap: 45px;
          .sub-title-pc {
            margin-top: 13px;
            display: block;
            width: 576px;
            height: 56px;
            line-height: 56px;
            background-color: #ffc65b;
            border-radius: 28px;
            font-size: 28px;
            color: #3d3d3d;
            text-align: center;
          }

          .text-content {
            width: 615px;
            font-size: 20px;
            color: #3d3d3d;
            margin-top: 22px;
            line-height: 32px;
          }
          .illustration {
            width: 340px;
          }
        }
      }
    }
    .block3 {
      .content {
        padding: 10px 0 0 0;
        .title-w {
          .title {
            width: 620px;

            .circle-small {
              bottom: 10px;
              right: -20px;
            }
          }
        }
        .sub-title {
          font-size: 24px;
          text-align: center;
          margin-top: 24px;
        }
        .text-content-w {
          margin-top: -20px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          .text-content {
            .item {
              font-size: 20px;
              margin-top: 15px;
              div:first-child {
                width: 26px;
                height: 26px;
                font-size: 18px;
              }
              .custom {
                width: 600px;
              }
            }
          }
          .illustration {
            display: block;
            width: 495px;
          }
        }
      }
    }
    .block4 {
      padding: 35px 4px 40px 4px;
      .content {
        .title-w {
          .title {
            .circle-big {
              top: -10px;
              left: 130px;
            }
            .circle-small {
              bottom: 10px;
            }
          }
          .sub-title {
            margin-top: 17px;
            font-size: 24px;
            color: #3d3d3d;
            font-weight: 400;
          }
        }
        .list {
          justify-content: space-between;
          margin-top: 20px;
          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 220px;
            border-radius: 10px;
            background-color: white;
            padding: 23px 0 18px 0;
            .icon {
              width: 77px;
              height: 77px;
              line-height: 77px;
              img {
                width: 42px;
                height: 42px;
              }
            }
            .text {
              font-size: 26px;
              margin-top: 18px;
            }
            .text1,
            .text2 {
              font-size: 20px;
            }
            .text1 {
              margin-top: 5px;
            }
          }
        }
      }
    }
    .block5 {
      .content {
        padding: 50px 0 0 0;
        .title-w {
          .title {
            width: 420px;
            .circle-big {
              top: -5px;
              left: 0;
            }
            .circle-small {
              bottom: 10px;
              right: 2px;
            }
          }
          .line {
            margin-top: 21px;
          }
        }
        .text-content {
          width: 812px;
          border-radius: 10px;
          margin: 35px auto 0;
          padding: 12px 0px 12px 48px;
          row-gap: 5px;
          align-items: flex-start;
          div {
            font-size: 20px;
            &::before {
              width: 8px;
              height: 8px;
              top: 36%;
              left: -20px;
            }
          }
          .single {
            &::before {
              top: 35%;
            }
          }
        }
        .sub-title {
          width: 387px;
          height: 52px;
          line-height: 52px;
          border-radius: 26px;
          margin: 55px auto 0;
          font-size: 28px;
        }
        .graphic-content-wrapper {
          column-gap: 6px;
          padding: 0;
          overflow: hidden;
          margin-top: 12px;
          .column {
            width: 175px;
            row-gap: 10px;
            .b1 {
              border-radius: 10px;
              padding: 30px 0px 40px 0;
              .text1 {
                font-size: 28px;
              }
              .text2 {
                width: 150px;
                height: 48px;
                line-height: 48px;
                border-radius: 24px;
                font-size: 24px;
                margin: 10px auto 0;
              }
              .text3 {
                font-size: 18px;
                line-height: 36px;
                margin-top: 20px;
              }
              .text4 {
                font-size: 18px;
                line-height: 36px;
              }
              .text5 {
                font-size: 14px;
                width: 162px;
                height: 200px;
                margin: 10px auto 0;
                text-align: justify;
                padding: 0 6px;
              }
            }
            .b2,
            .b3,
            .b4,
            .b5 {
              font-size: 18px;
            }
            .b6 {
              font-size: 14px;
              padding: 6px 4px;
            }
            .b2 {
              div {
                width: 84px;
                height: 32px;
                line-height: 32px;
                border-radius: 6px;
                border: 1px solid #cacaca;
              }
            }
            .b3,
            .b4,
            .b5,
            .b6 {
              border-radius: 6px;
            }
          }
          .column-0 {
            width: 150px;
            .b1 {
              .text3,
              .text4 {
                font-size: 18px;
                padding-right: 10px;
                text-align: right;
              }
              .text5 {
                padding: 0 20px;
                font-size: 18px;
                text-align: right;
              }
            }
            .b3 {
              padding: 3px 10px 1px 0;
            }
            .b6 {
              padding: 4px 0 6px 0;
              padding-right: 10px;
              font-size: 18px;
              line-height: 44px;
            }
          }
          .column-2 {
            .b1 {
              .text3 {
                margin-top: 45px;
              }
            }
          }
          .column-3 {
            .b1 {
              .text3 {
                margin-top: 70px;
              }
            }
          }
          .column-4 {
            .b1 {
              .text3 {
                margin-top: 95px;
              }
            }
          }
          .double {
            row-gap: 10px;
            .top {
              column-gap: 6px;

              .column-5 {
                .b1 {
                  .text3 {
                    margin-top: 120px;
                  }
                }
              }
              .column-6 {
                .b1 {
                  .text3 {
                    margin-top: 145px;
                  }
                }
              }
            }
            .bottom {
              row-gap: 10px;
              div {
                font-size: 18px;
                border-radius: 6px;
              }
              .b3 {
                font-size: 14px;
                line-height: 56px;
              }
            }
          }
        }
      }
    }
    .block6 {
      .content {
        padding: 70px 0 60px 0;
        .title-w {
          .title {
            width: 550px;
            .circle-big {
              top: -10px;
              left: -35px;
            }
            .circle-small {
              bottom: 5px;
              right: -10px;
            }
          }
          .line {
            margin-top: 20px;
          }
        }
        .list {
          flex-direction: row;
          column-gap: 25px;
          margin-top: 43px;
          .item {
            width: 385px;
            flex-direction: column;
            row-gap: 20px;
            border-radius: 10px;
            font-size: 20px;
            padding: 20px 35px;
            .img1 {
              width: 148px;
              height: 126px;
            }
            .img2 {
              width: 143px;
              height: 126px;
            }
            .img3 {
              width: 130px;
              height: 126px;
            }
          }
        }
      }
    }
    .block7 {
      padding: 35px 0px 30px 0px;
      .content {
        .title-w {
          margin-bottom: 28px;
          .title {
            width: 580px;
            .circle-big {
              top: -10px;
              left: -25px;
            }
            .circle-small {
              bottom: 5px;
            }
          }
          .line {
            margin-top: 24px;
          }
        }
        .text-content {
          font-size: 18px;
          margin-top: 0px;
          padding: 0 75px;
        }
        .list {
          justify-content: space-between;
          column-gap: 20px;
          margin-top: 45px;
          .item {
            width: 272px;
            border-radius: 10px;
            padding: 18px 10px 12px 10px;
            .name {
              font-size: 32px;
            }
            .line {
              width: 58px;
              height: 6px;
              border-radius: 3px;
              margin-top: 12px;
            }
            .sub-list {
              padding: 20px 10px 0 20px;
              row-gap: 5px;
              .sub-item {
                font-size: 18px;
                .dot {
                  width: 6px;
                  height: 6px;
                  border-radius: 3px;
                  top: 10px;
                  left: -15px;
                }
              }
            }
          }
        }
      }
    }
    .block8 {
      .content {
        padding: 66px 30px 0 30px;
        .title-w {
          .title {
            width: 190px;
          }
        }
        .list {
          margin-top: 40px;
          column-gap: 60px;
          .item {
            width: 540px;
            border-radius: 10px;
            padding: 0 25px 20px 20px;
            .title {
              width: 297px;
              height: 54px;
              line-height: 54px;
              font-size: 26px;
            }
            .b1 {
              padding-top: 85px;
              font-size: 18px;

              .right {
                width: 200px;
                border-radius: 20px;
                margin-left: 85px;
                padding: 6px 15px;
              }
              .right2 {
                text-align: center;
                width: 200px;
                margin-left: 85px;
              }
            }
            .b-d {
              padding-top: 85px;
            }
            .b2 {
              font-size: 18px;
              margin-top: 24px;
              justify-content: left;
              column-gap: 60px;
              .left {
                font-weight: bold;
              }
              .right {
                width: 294px;
                row-gap: 7px;
                div {
                  &::before {
                    width: 8px;
                    height: 8px;
                    top: 10px;
                    left: -18px;
                  }
                }
              }
            }
            .b2-d {
              margin-top: 24px;
            }
            .b3,
            .b4 {
              font-size: 18px;

              .right {
                margin-left: 40px;
              }
            }
            .b3 {
              margin-top: 15px;
            }
            .b4 {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .block9 {
      .content {
        padding: 80px 0 33px 0;
        .title-w {
          .title {
            width: 690px;
            .circle-big {
              left: -10px;
            }
            .circle-small {
              bottom: 0;
              right: -20px;
            }
          }
        }
        .wechat-pics {
          overflow: hidden;
          margin-top: 40px;
          padding: 0 15px 14px 0;
          img {
            width: 1200px;
            height: 486px;
          }
        }
      }
    }
    .block10 {
      padding: 44px 0 40px 0;

      .title {
        font-size: 36px;
      }
      .line {
        width: 161px;
        height: 12px;
        border-radius: 6px;
        margin: 20px auto 0;
      }
      .contact-text {
        font-size: 26px;
        margin-top: 20px;
      }
      img {
        width: 199px;
        height: 193px;
        margin-top: 12px;
      }
    }
  }
}
