.follow-btn {
  margin-left: 18px;
  height: 45px !important;
  width: 120px !important;
  font-size: 20px !important;
  font-weight: 600;
}

.followed-btn {
  background-color: #f0f0f0;
  color: #7b7b7b;
  border: none;
}

.followed-btn[disabled] {
  background-color: #f0f0f0 !important;
  color: #7b7b7b !important;
  border: none !important;
  cursor: default !important;
}

.add-group-btn {
  background-color: #f9ba48;
  color: #ffffff;
  border: none;
}

@media screen and (max-width: 1175px) {
  .follow-btn {
    width: auto !important;
    height: 24px !important;
    padding: 0 12px !important;
    font-size: 12px !important;
    margin-left: 8px;
  }
}
