.english {
  .section-1 {
    padding-top: 74px;
    position: relative;
    .nav {
      position: relative;
      width: 1175px;
      margin: auto;
      background: rgba(70, 70, 70, 0.69);
      border-radius: 66px;
      padding: 14px 39px;
      img {
        width: 245px;
      }
      .nav-menu-icon {
        position: absolute;
        display: none;
        right: 28px;
        top: 50%;
        transform: translateY(-50%);
      }
      .text {
        display: inline-flex;
        flex-direction: column;
        color: #efb44a;
        margin-left: 20px;
        .chinese {
          font-size: 40px;
          font-weight: 900;
          line-height: 40px;
          letter-spacing: 15px;
        }
        .english {
          font-size: 15px;
          line-height: 20px;
        }
      }
      .nav-menu {
        display: inline-flex;
        justify-content: space-between;
        width: 35%;
        margin-left: 16%;
        color: white;
        font-size: 20px;
        font-weight: 500;
        div {
          cursor: pointer;
        }
      }
    }
    .content-wrapper {
      position: absolute;
      top: 254px;
      left: 50%;
      transform: translateX(-50%);
      width: 1175px;
      display: flex;
      justify-content: space-between;
      margin: auto;
      text-align: center;
      .item {
        width: 49%;
        background-color: white;
        border-radius: 18px;
        text-align: center;
        padding: 51px 0;
        box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.03);
        .text-1 {
          font-weight: 600;
          font-size: 38px;
          line-height: 128%;
          color: #1f1f1f;
        }
        .text-4 {
          font-size: 16px;
          font-weight: 900;
          color: #1f1f1f;
        }
        .line {
          width: 456px;
          border-top: 1px solid #d6d6d6;
          margin: 15px auto;
        }
        .text-2 {
          img {
            width: 360px;
          }
        }
        .items-wrapper {
          margin-top: 35px;
          div {
            font-size: 24px;
            line-height: 184.8%;
            font-weight: 600;
            letter-spacing: 0.24em;
            color: white;
            background-color: #fbad22;
            padding: 15px 20px;
            border-radius: 55px;
            width: 349px;
            margin: 0 auto;
            margin-top: 10px;
            text-align: left;
            & > :first-child {
              margin-right: 10px;
            }
          }
        }
        .member-wrapper {
          width: 410px;
          margin: 0 auto;
          margin-top: 60px;
          text-align: left;
          img {
            width: 36.8px;
            height: 36.8px;
            margin-top: -15px;
            border-radius: 50%;
          }
          .omit {
            display: inline-block;
            font-size: 25px;
            margin-top: -20px;
          }
        }
        .text-3 {
          font-size: 24px;
          font-weight: 700;
          color: #fbad22;
          margin-top: 28px;
        }
        .common-btn {
          img {
            width: 211px;
            margin-top: 35px;
            margin-left: 25px;
          }
        }
      }
      & > :not(:first-child) {
        .items-wrapper {
          div {
            & > :first-child {
              margin-left: 70px;
            }
          }
        }
      }
    }
    .white-block {
      border-top-left-radius: 103px;
      border-top-right-radius: 103px;
      background-color: #f9f9f9;
      height: 52px;
      margin-top: 366px;
    }
  }
  .section-2 {
    background-color: #f9f9f9;
    text-align: center;
    padding: 670px 0 0 0;
    & > :first-child {
      width: 71px;
      height: 71px;
      margin: 0 auto;
    }
    .phone-table-wrapper {
      display: none;
    }
    .table-wrapper {
      width: 88.9%;
      margin: 0 auto;
      margin-top: 51px;
      padding: 65px 25px 85px 25px;
      border-radius: 40px;
      font-size: 20px;
      line-height: 24px;
      & > div {
        display: flex;
        align-items: center;
        padding: 20px 0;
        img {
          width: 114px;
        }
        & > div {
          width: 200px;
          & > div:last-child {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }
      .big {
        width: 240px;
      }
      & > div:not(:first-child) {
        background-color: white;
        border-radius: 22px;
        margin-top: 20px;
      }
      & > div:first-child {
        color: white;
        font-weight: 600px;
        line-height: 24px;
        font-size: 24px;
      }
    }
  }

  .section-3 {
    .text-1 {
      text-align: center;
      margin-top: 67px;
      img {
        width: 192px;
      }
    }
    .text-2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      color: #9a9a9a;
      margin-top: 15px;
      text-align: center;
    }
    .my-swiper {
      position: relative;
      overflow: hidden;
      margin-top: 56px;
      padding-top: 56px;
      padding-bottom: 76px;
      background-color: #f2f2f2;
      .cover-img-l {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        --tw-blur: blur(40px);
        pointer-events: none;
      }
      .cover-img-r {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;
        transform: rotate(180deg);
        pointer-events: none;
      }
    }
    .carousels-web {
      display: block;
    }
    .carousels-phone {
      display: none;
    }
    .carousels .img-wrapper {
      display: flex;
      width: 100%;
      height: 120px !important;
      border: 4px solid white;
      border-radius: 24px;
      box-sizing: border-box;
      overflow: hidden;
      align-items: middle;
    }
    .carousels .img-wrapper img {
      width: 100%;
      object-fit: cover;
      transform: scale(1.1);
      flex: 1;
    }
    .image-view {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(17, 24, 39, 0.4);
      z-index: 10;
      .wrapper {
        transform: scale(0.75);
        position: relative;
      }
      .mobile-image-item-wrapper {
        img {
          width: 98vw;
        }
      }
      .web-image-item-wrapper {
        img {
          height: 100vh;
        }
      }
      .image-item-wrapper {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .close {
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        font-size: 48px;
        color: white;
        border-radius: 50%;
        background-color: rgba(249, 250, 251, 0.4);
        width: 48px;
        height: 48px;
        text-align: center;
        margin-top: 5px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        div {
          position: absolute;
          top: 50%;
          transform: translateY(-55%);
        }
      }
    }
  }

  .swiper-button-prev {
    background-image: url("https://duckfiles.oss-cn-qingdao.aliyuncs.com/images/english/button-pre.png") !important;
    width: 52px !important;
    height: 52px !important;
    position: fixed !important;
    background-size: 100% 100%;
  }

  .swiper-button-next {
    background-image: url("https://duckfiles.oss-cn-qingdao.aliyuncs.com/images/english/button-next.png") !important;
    width: 52px !important;
    height: 52px !important;
    position: fixed !important;
    background-size: 100% 100%;
  }

  .swiper-button-prev:after {
    content: "none" !important;
  }

  .swiper-button-next:after {
    content: "none" !important;
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    pointer-events: auto !important;
  }

  // .swiper-button-prev {
  // 	color: white !important;
  // }

  // .swiper-button-next {
  // 	color: white !important;
  // }

  // .swiper-button-next.swiper-button-disabled,
  // .swiper-button-prev.swiper-button-disabled {
  // 	pointer-events: auto !important;
  // }

  .section-4 {
    text-align: center;
    margin-top: 67px;
    padding: 36px 0;
    background-color: #323639;
    .left {
      display: inline-block;
    }
    .logo {
      display: inline-block;
      vertical-align: middle;
    }
    .v-line {
      height: 56px;
      background-color: rgba(107, 114, 128, 1);
      vertical-align: middle;
      margin: 36px 0;
      width: 1px;
      display: inline-block;
      margin-left: 60px;
    }
    .h-line {
      display: none;
    }
    .slogan {
      font-size: 14px;
      line-height: 30px;
      display: inline-block;
      vertical-align: middle;
      color: #9b9b9b;
      width: 384px;
      margin-left: 36px;
      text-align: left;
    }
    .right {
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      margin-left: 60px;
      .copy-right {
        font-size: 14px;
        line-height: 20px;
        color: #9b9b9b;
        text-align: left;
        display: inline-block;
      }
      span:not(:first-child) {
        display: inline-block;
        line-height: 2;
        margin-left: 48px;
      }
    }
  }

  .my-popup {
    width: 100px;
    padding: 10px 0;

    & > div:not(:first-child) {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 1175px) {
    .section-1 {
      .nav {
        width: 95%;
        .nav-menu {
          display: none;
        }
        .nav-menu-icon {
          display: inline-block;
          img {
            width: 30px;
          }
        }
      }
      .content-wrapper {
        width: 95%;
        top: 230px;
        .item {
          .line {
            width: 95%;
          }
          .member-wrapper {
            width: 90%;
          }
        }
        & > :not(:first-child) {
          .items-wrapper {
            div {
              & > :first-child {
                margin-left: 25px;
              }
            }
          }
        }
      }
      .white-block {
        margin-top: 120px;
      }
    }
  }
  @media screen and (max-width: 820px) {
    .section-2 {
      padding: 870px 0 0 0;
    }
  }
  @media screen and (max-width: 768px) {
    .section-1 {
      padding-top: 30px;
      .nav {
        padding: 14px 20px;
        img {
          width: 140px;
        }
        .nav-menu-icon {
          display: inline-block;
          img {
            width: 22px;
          }
        }
        .text {
          margin-left: 12px;
          .chinese {
            font-size: 25px;
            line-height: 25px;
            letter-spacing: 11px;
          }
          .english {
            font-size: 10px;
            line-height: 12px;
          }
        }
        .nav-menu {
          display: none;
        }
      }
      .content-wrapper {
        width: 95%;
        top: 130px;
        .item {
          padding: 30px 0;
          .text-1 {
            font-size: 18px;
            line-height: 20px;
          }
          .text-4 {
            font-size: 10px;
            line-height: 20px;
          }
          .text-2 {
            img {
              width: 90%;
            }
          }
          .line {
            width: 95%;
            margin: 10px auto;
          }
          .items-wrapper {
            margin-top: 20px;
            div {
              font-size: 12px;
              padding: 7px 0px 7px 10px;
              width: 95%;
              & > :first-child {
                width: 12px;
                margin-right: 6px;
              }
            }
          }
          .member-wrapper {
            width: 90%;
            margin: 0 auto;
            margin-top: 28px;
            line-height: 9px;
            img {
              width: 14px;
              height: 14px;
            }
            .omit {
              display: none;
            }
          }
          .text-3 {
            font-size: 10px;
            margin-top: 8px;
          }
          .common-btn {
            img {
              margin: 0 auto;
              margin-top: 10px;
              width: 85%;
            }
          }
          & > :not(:first-child) {
            .items-wrapper {
              div {
                & > :first-child {
                  margin-left: 25px;
                }
              }
            }
          }
        }
      }
    }
    .section-2 {
      padding: 387px 0 0 0;
      & > :first-child {
        width: 50px;
        height: 50px;
      }
      .table-wrapper {
        display: none;
      }
      .phone-table-wrapper {
        display: inline-block;
        width: 95%;
        margin: 0 auto;
        position: relative;
        margin-top: 15px;
        .mid {
          position: absolute;
          left: 50%;
          transform: translateX(-50%;);
          background-color: #ffab14;
          box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.03);
          border-radius: 18px;
          font-size: 12px;
          top: 75px;
          color: white;
          padding: 15px 0 35px 0;
          width: 26%;
          div {
            margin-top: 20px;
          }
        }
        .items-wrapper {
          font-size: 12px;
          color: #7d7d7d;
          display: flex;
          justify-content: space-between;
          .item {
            width: 40%;
            background-color: white;
            box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.03);
            border-radius: 18px;
            padding: 20px 0;
            img {
              width: 100px;
            }
            div {
              margin-top: 20px;
            }
          }
          & > :not(:first-child) {
            & > div {
              img {
                width: 90px;
                padding-bottom: 7px;
              }
            }
          }
        }
      }
    }
    .section-3 {
      .text-1 {
        margin-top: 40px;
        img {
          width: 25%;
        }
      }
      .text-2 {
        font-size: 10px;
        margin-top: 0px;
      }
      .my-swiper {
        margin-top: 16px;
        padding-top: 26px;
        padding-bottom: 46px;
        .carousels-web {
          display: none;
        }
        .carousels-phone {
          display: block;
        }
      }
    }
    .section-4 {
      padding: 40px 16px;
      margin-top: 20px;
      .left {
        display: block;
        text-align: left;
        div {
          display: inline-block;
        }
        .slogan {
          width: 100%;
          margin-left: 0px;
          text-align: justify;
        }
      }
      .v-line {
        display: none;
      }
      .h-line {
        display: block;
        width: 100%;
        height: 1px;
        background-color: rgba(107, 114, 128, 1);
        margin: 24px 0;
      }
      .right {
        display: block;
        margin-left: 0;
        text-align: left;
        .copy-right {
          span {
            display: block;
            margin-left: 0;
          }
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;
    }
  }
}
