.java-wrapper {
  margin-top: -20px;
  * {
    box-sizing: border-box;
  }
  background-color: white;
  .title-w {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    .title {
      padding: 0 10px;
    }
    .title-sm {
      padding: 0 5px;
    }
    .title-white {
      color: white;
    }
    img {
      width: 128px;
      &:nth-child(3) {
        transform: rotate(180deg);
      }
    }
    * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .block1 {
    width: 100%;
    position: relative;
    background: url("~/assets/fullstack/p1@3x.png");
    background-size: cover;
    background-repeat: no-repeat;
    .content {
      padding: 20px 0 20px 21px;
      .left {
        .text {
          font-size: 28px;
          line-height: 40px;
          font-weight: bold;
        }
        .p2 {
          width: 100px;
          margin-top: 24px;
        }
      }
    }
    .p3 {
      position: absolute;
      width: 250px;
      bottom: 0;
      right: 0;
    }
  }
  .block2 {
    .content {
      padding: 15px 0px;
    }
    .pc-list-content {
      display: none;
    }
    .mp-list-content {
      position: relative;
      .list-w {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 68px;
        padding: 0 28px;
        margin-top: 25px;
        .item {
          background: url("~/assets/java/p5@3x.png");
          background-size: contain;
          width: 138px;
          height: 84px;
          padding: 12px;
          font-size: 12px;
        }
        .item-last {
          padding: 20px 18px;
        }
      }
      .item-long {
        position: relative;
        background: url("~/assets/java/p7@3x.png");
        background-size: contain;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        width: 276px;
        margin: 18px auto;
        padding-left: 20px;

        div {
          position: absolute;
          left: -15px;
          top: 0;
          width: 30px;
          height: 30px;
          line-height: 30px;
          color: white;
          background-color: #5b5b5b;
          border-radius: 50%;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .mid {
        position: absolute;
        top: 19%;
        left: 30%;
        width: 130px;
      }
    }
  }
  .block3 {
    .content {
      padding: 20px 0;
    }
    .list-w {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 23px;
      .item {
        width: 162px;
        height: 231px;
        border-radius: 9px;
        position: relative;
        text-align: center;
        padding-top: 100px;
        margin-top: 23px;

        .text1 {
          font-size: 18px;
          font-weight: bold;
        }
        .text2 {
          font-size: 14px;
          text-align: left;
          width: 142px;
          margin: 0 auto;
          margin-top: 6px;
        }
      }
      .item1 {
        background: url("~/assets/java/p15@3x.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
      .item2 {
        background: url("~/assets/java/p16@3x.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
      .item3 {
        background: url("~/assets/java/p17@3x.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
      .item4 {
        background: url("~/assets/java/p18@3x.png");
        background-size: contain;
        background-repeat: no-repeat;
        padding-top: 90px;
        .text2 {
          width: 150px;
        }
      }
    }
  }
  .block4 {
    .ant-collapse > .ant-collapse-item,
    .ant-collapse-header,
    .ant-collapse-content-box {
      background-color: white;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0px 20px !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 20px;
    }
    .ant-collapse-header-text {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
      margin-top: 14px;
    }
    .without-line .ant-collapse-header-text {
      border: none !important;
    }
    .content {
      .ac-item-title {
        display: flex;
        width: 100%;
        height: 36px;
        border-radius: 18px;
        background-color: #f3f3f3;
        font-size: 14px;
        font-weight: 500;
        color: #3d3d3d;
        justify-content: space-between;
        padding-left: 18px;
        align-items: center;
        border: 1px solid #dbdbdb;
        img {
          width: 95px;
          margin-right: -10px;
          margin-top: 6px;
        }
      }
      .ac-item {
        padding: 6px 10px 8px 0px;
        background-color: white;
        font-size: 14px;
        color: #5d5d5d;
        border-radius: 0 0 10px 10px;
        border-left: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          .title {
            position: relative;
            color: #3d3d3d;
            font-weight: 600;
            padding-left: 15px;
            padding-top: 5px;
            &::before {
              content: "";
              position: absolute;
              width: 5px;
              height: 19px;
              background-color: #f9ba48;
              top: 31%;
              transform: translateX(-50%);
              left: 5px;
            }
          }
          .title-mt {
            margin-top: 5px;
          }
          p {
            padding-left: 18px;
          }
          .p-mt {
            span {
              display: block;
              line-height: 25px;
            }
            margin-top: 10px;
          }
          .line {
            border: none;
            border-top: 1px dotted #a4a4a4;
            height: 1px;
            width: 100%;
            margin: 10px 0;
          }
        }
        p {
          margin-bottom: 0px !important;
          line-height: 33px;
        }
      }
    }
  }
  .block5 {
    .content {
      padding: 30px 0;
      .list-w {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0px 30px;
        row-gap: 30px;
        margin-top: 15px;
        .item {
          width: 150px;
          text-align: center;
          font-size: 14px;
          img {
            width: 66.5px;
            margin-bottom: 7px;
          }
        }
      }
    }
  }
  .block6 {
    background: linear-gradient(to right, #ffefd3, #fabc4b);
    .content {
      padding: 16px 0 20px 0;
      .list-w {
        display: flex;
        font-size: 14px;
        flex-wrap: wrap;
        padding: 0 10px;
        .item {
          width: 100%;
          div {
            width: 100%;
            background-color: #f0b64a;
            border-radius: 18px;
            padding: 7px 26px;
            margin-top: 10px;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: black;
              top: 16px;
              left: 15px;
            }
          }
        }
        img {
          display: none;
        }
      }
    }
  }
  .block7 {
    .content {
      padding: 35px 0;
      .list-w {
        display: flex;
        flex-wrap: wrap;
        padding: 0 10px;
        width: 100%;
        row-gap: 36px;
        margin-top: 25px;

        .item {
          position: relative;
          width: 100%;
          border-radius: 10px;
          border: 1px solid #dbdbdb;
          padding: 30px 0 5px 15px;

          .name {
            position: absolute;
            background: url("~/assets/java/p32@3x.png");
            background-size: contain;
            width: 98px;
            height: 32px;
            font-size: 20px;
            font-weight: bold;
            line-height: 32px;
            padding-left: 15px;
            top: -15px;
            left: 10px;
          }
          .text {
            font-size: 14px;
            position: relative;
            padding-left: 3px;
            line-height: 30px;
            &::before {
              content: "";
              position: absolute;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              top: 13px;
              left: -5px;
              background-color: black;
            }
          }
          background: linear-gradient(to bottom, #fff2db, #fffefe);
        }
      }
    }
  }
}
@media screen and (min-width: 1441px) {
  .block1 {
    .p3 {
      width: 980px !important;
    }
  }
}
@media screen and (min-width: 1175px) and (max-width: 1440px) {
  .block1 {
    .p3 {
      width: 780px !important;
    }
  }
}

@media screen and (min-width: 1175px) {
  .java-wrapper {
    .content {
      width: 1200px;
      margin: 0 auto;
    }
    .title-w {
      font-size: 36px;
      .title {
        padding: 0 15px;
      }
      img {
        width: 280px;
      }
    }

    .block1 {
      .content {
        padding: 92px 0 42px 0px;
        .left {
          .text {
            font-size: 90px;
            line-height: 100px;
            font-weight: bold;
          }
          .p2 {
            width: 250px;
            margin-top: 85px;
          }
        }
      }
    }
    .block2 {
      .content {
        padding: 75px 0 40px 0;
        .mp-list-content {
          display: none;
        }
        .pc-list-content {
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          align-items: center;
          .list-w {
            .item {
              position: relative;
              background: url("~/assets/java/p8@3x.png");
              background-size: contain;
              height: 54px;
              line-height: 54px;
              width: 711px;
              margin-top: 10px;
              font-size: 24px;
              padding-left: 55px;
              div {
                position: absolute;
                left: 7px;
                top: 7px;
                width: 41px;
                height: 41px;
                line-height: 41px;
                color: white;
                background-color: #5b5b5b;
                border-radius: 50%;
                text-align: center;
                font-size: 24px;
                font-weight: bold;
              }
            }
          }
          .p10 {
            width: 350px;
          }
        }
      }
    }
    .block3 {
      .content {
        padding: 40px 0 20px 0;
      }
      .list-w {
        display: flex;
        justify-content: space-between;
        padding: 0px;

        .item {
          width: 268px;
          height: 349px;
          padding-top: 160px;

          .text1 {
            font-size: 28px;
          }
          .text2 {
            font-size: 20px;
            width: 240px;
            margin: 0 auto;
            margin-top: 27px;
            // text-align: justify;
          }
        }
        .item1 {
          background: url("~/assets/java/p155@3x.png");
          background-size: contain;
          background-repeat: no-repeat;
        }
        .item2 {
          background: url("~/assets/java/p166@3x.png");
          background-size: contain;
          background-repeat: no-repeat;
        }
        .item3 {
          background: url("~/assets/java/p177@3x.png");
          background-size: contain;
          background-repeat: no-repeat;
        }
        .item4 {
          background: url("~/assets/java/p188@3x.png");
          background-size: contain;
          background-repeat: no-repeat;
          padding-top: 135px;
          .text2 {
            width: 230px;
            margin-top: 9px;
          }
        }
      }
    }
    .block4 {
      .content {
        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
          padding: 0px 0px !important;
        }
        .ant-collapse-content > .ant-collapse-content-box {
          padding: 0 0px;
        }
        padding: 25px 0;
        .ant-collapse {
          margin-top: 10px;
        }
        .ant-collapse-header-text {
          border-top-left-radius: 35px;
          border-top-right-radius: 35px;
          margin-top: 24px;
        }
        .ac-item-title {
          height: 71px;
          border-radius: 35px;
          background-color: #f3f3f3;
          font-size: 26px;
          padding-left: 34px;
          border: 1px solid #d8d8d8;
          img {
            width: 187px;
            margin-right: -23px;
            margin-top: 13px;
          }
        }
        .ac-item {
          padding: 26px 32px 26px 0;
          font-size: 20px;
          border-radius: 0 0 35px 35px;
          background-color: white;
          .item {
            width: 520px;
            .title {
              position: relative;
              color: #3d3d3d;
              font-weight: 600;
              padding-left: 30px;
              padding-bottom: 10px;
              font-size: 24px;
              &::before {
                width: 5px;
                height: 25px;
                background-color: #f9ba48;
                top: 20%;
                left: 15px;
              }
            }
            .title-mt {
              margin-top: 25px;
            }
            .item-mt {
              margin-top: 50px !important;
            }
            p {
              padding-left: 35px;
            }
            .line {
              display: none;
            }
            .p-item {
              span {
                display: block;
                line-height: 35px;
              }
            }
            .p-mt {
              margin-top: 23px;
            }
            .p-mt-big {
              margin-top: 50px;
            }
            .p-mt-big-2 {
              margin-top: 95px;
            }
          }

          p {
            margin-bottom: 6px !important;
            line-height: 33px;
          }
        }
      }
    }
    .block5 {
      .content {
        padding: 60px 0 75px 0;
        .list-w {
          padding: 0 60px;
          margin-top: 55px;
          .item {
            width: 240px;
            font-size: 24px;
            img {
              width: 128px;
              margin-bottom: 17px;
            }
          }
        }
      }
    }
    .block6 {
      // background: url("~/assets/java/p26@3x.png");
      background: linear-gradient(to right, #ffefd3, #fabc4b);
      background-size: contain;
      .content {
        padding: 27px 0 70px 0;
        .list-w {
          padding: 0 60px;
          justify-content: space-between;
          flex-wrap: nowrap;
          font-size: 18px;
          position: relative;
          margin-top: 50px;
          .item {
            width: 380px;

            div {
              border-radius: 40px;
              padding: 5px 30px;
              line-height: 24px;
              &::before {
                top: 14px;
              }
            }
          }
          img {
            display: block;
            position: absolute;
            width: 280px;
            left: 38%;
            top: -27%;
          }
        }
      }
    }
    .block7 {
      .content {
        padding-top: 60px;
        .list-w {
          flex-wrap: nowrap;
          justify-content: space-around;
          margin-top: 70px;
          padding: 0px;
          .item {
            width: 366px;
            padding: 40px 0 0 25px;
            .name {
              font-size: 32px;
              width: 153px;
              height: 50px;
              line-height: 50px;
              padding-left: 30px;
              top: -26px;
              left: 15px;
            }
            .text {
              font-size: 18px;
              padding: 0px 15px 0 10px;
              line-height: 35px;
              &::before {
                width: 6px;
                height: 6px;
                top: 15px;
              }
            }
            .text-1 {
              line-height: 25px;
              &::before {
                width: 6px;
                height: 6px;
                top: 10px;
              }
            }
          }
          .item-last {
            padding: 30px 0 20px 25px !important;
          }
        }
      }
    }
  }
}
