.apk-top {
  width: 100%;
  height: 50px;
  background-image: url("~/assets/images/apk-top.jpg");
  background-size: contain;
  background-repeat: repeat-x;
}
.apk {
  width: 750px;
  margin: 50px auto;
  .latest-version {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    .latest-version-item {
      width: 44%;
      display: flex;
      padding: 10px 0px;
      border-bottom: 1px solid #e5e5e5;
      justify-content: space-between;
    }
  }
  .divider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    margin: 30px 0;
    .line {
      flex: 1;
      height: 1px;
      background-color: #e5e5e5;
      margin: 0 10px;
    }
  }
  .release-note {
    font-size: 16px;
    margin-top: 30px;
    ul {
      padding-left: 16px !important;
      li {
        padding: 5px 0;
      }
    }
  }
  .apk-version-list {
    font-size: 16px;
    .apk-version-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 4px;
      cursor: pointer;
      &:nth-child(odd) {
        background: #ede8dd;
      }
      &:nth-child(even) {
        background: #f8f7f5;
      }
      .release-note-short {
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .version {
        position: relative;
        max-width: 180px;
        text-align: left;
        .new-tag {
          position: absolute;
          right: -55px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .apk-version-list-more {
      cursor: pointer;
      margin-top: 10px;
      .apk-version-list-more-icon {
        transform: rotate(90deg);
        margin: 5px;
      }
    }
  }
  .description > div {
    margin-top: 20px;
    div {
      line-height: 30px;
    }
  }
}

.apk-overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  z-index: 999;
  font-size: 22px;
  img {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .content {
    padding: 100px 0 0 40px;
    .one {
      display: flex;
      align-items: center;
      .more-wrapper {
        position: relative;
        margin-left: 10px;
        width: 50px;
        height: 40px;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.5);
        .more {
          position: absolute;
          color: white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
    .two {
      margin-top: 15px;
    }
    .num {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      line-height: 30px;
      text-align: center;
      color: white;
      background-color: #f9ba48;
      margin-right: 10px;
    }
  }
}
.apk-download-top {
  position: relative;
  padding: 30px 80px 30px 20px;
  font-size: 18px;
  background: grey;
  color: rgb(209, 203, 203);
  .arrow {
    width: 60px;
    position: absolute;
    right: 15px;
    top: 20px;
  }
}
.show-overlay {
  display: flex;
}
@media screen and (max-width: 480px) {
  .apk {
    width: 95%;
    .latest-version {
      .latest-version-item {
        width: 100%;
      }
    }
    .apk-version-list {
      .apk-version-item {
        .release-note-short {
          width: 60px;
        }
        .version {
          width: 35%;
          .new-tag {
            right: -37px;
          }
        }
      }
    }
  }
}
