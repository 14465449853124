.item-wrapper {
  &:hover {
    background-color: #f5f5f5;
  }
  .item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top {
      .title span {
        display: inline-block;
        vertical-align: middle;
        &:hover {
          color: #f9ba48;
        }
      }
    }
    .status {
      color: #f9ba48;
    }
  }
  .update-at {
    font-size: 12px;
    color: #aaa;
    margin-top: 10px;
  }
  border-bottom: 1px solid #f2f1f1;
  padding: 10px 10px;
  cursor: pointer;
}
.selected {
  background-color: #f5f5f5;
}

.status-wrapper {
  .title {
    font-weight: 900;
  }
  .content {
    font-weight: 500;
    font-size: 12px;
  }
  @media only screen and (min-width: 1170px) {
    .vertical-timeline-element {
      margin: 1em 0 !important;
    }
  }
  .vertical-timeline {
    padding: 0 !important;
    .time-line-date {
      padding: 3px;
    }
    .time-line-content {
      padding: 5px;
    }
    .current-time-item {
      background: #f9ba48;
      width: 20px;
      height: 20px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .time-item {
      background: #f6f6f6;
      width: 20px;
      height: 20px;
      margin-top: 10px;
      margin-left: 10px;
    }
  }
}
