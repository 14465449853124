.field-item {
  display: flex;
  margin-bottom: 10px;
  line-height: 26px;
  flex-wrap: wrap;

  .field-label {
    flex: 100%;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &.block .field-label {
    display: none;
  }

  .field-body {
    flex: 100%;
  }

  @media (min-width: @screen-sm-min) {
    flex-wrap: nowrap;

    .field-label {
      flex: 0 0 70px;
      width: calc(100% - 340px);
      margin-bottom: 0;
    }

    .field-body {
      flex: 1;
    }
  }
}

