.links-label {
    font-size: 16px;
    color: #ffc700;
    margin-bottom: 8px;
}

.col {
    margin-bottom: 4px;
}

.add-notice {
    text-align: center;
    color: #777777;
}

.icon-img {
    margin-right: 6px;
}