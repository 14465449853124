.user-tag {
  color: #877d6a;
  font-size: 14px;
  padding: 3px 11px;
  background: #f5f3e7;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 4px;
  margin-bottom: 10px;
  display: inline-block;
  line-height: 20px;
}

.level-badge {
  position: relative;
  display: inline-block;
  height: 14px;
  width: 20px;
  top: 2px;

  img {
    position: absolute;
    height: 14px;
    left: 0;
    top: 0;
  }

  span {
    position: absolute;
    color: #e5a95b;
    font-size: 9px;
    line-height: 1;
    font-weight: bold;
    font-style: italic;
    bottom: 0;
    left: 10px;
  }
}

.user-info-block {
  margin: 10px;

  .user-info-box {
    display: flex;
    padding: 0 5px;
    align-items: center;

    h3 {
      color: #565a5c;
      line-height: 28px;
      font-size: 20px;
      margin-bottom: 0px;
    }

    .level-badge {
      margin-left: 10px;
    }
  }

  p {
    line-height: 28px;
    font-size: 12px;
    font-weight: 400;
    color: #9b9b9b;
    line-height: 17px;
    margin-bottom: 15px;
    padding: 0 5px;
  }

  .body {
    width: 100%;
    max-width: auto;
  }

  .extra {
    padding-left: 5px;
  }

  @media (min-width: @screen-sm-min) {
    .body {
      max-width: 500px;
    }
  }
}

.small {
  width: 45%;
}

@media (max-width: @screen-sm-min) {
  .small {
    width: 90%;
  }
}

.wechat-unbind-btn {
  margin-left: 10px;
}

.avatar-level {
  text-align: center;
  background: #fff;
  color: #f9ba48;
  border-radius: 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 56%;
  border: 1px solid #f9ba48;
  bottom: 0px;
  font-size: 9px;
  line-height: 9px;
  img {
    width: 7px;
    height: 7px;
  }

  &.small {
    font-size: 7px;
    line-height: 7px;
    bottom: -1px;
    width: 70%;
    img {
      width: 6px;
      height: 6px;
    }
  }
}
