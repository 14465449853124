.site-swiper {
  position: relative;
  z-index: 1;
  img {
    width: 100%;
    max-height: 508px;
    object-fit: cover;
  }
  .btn-wrapper {
    position: absolute;
    z-index: 2;
    bottom: 30px;
    right: 58px;
    display: flex;
    column-gap: 24px;
    .custom-prev {
      width: 38px;
      height: 38px;
      background: url("~/assets/cities/pre-btn.png");
      background-size: cover;
      cursor: pointer;
    }
    .custom-next {
      width: 38px;
      height: 38px;
      background: url("~/assets/cities/next-btn.png");
      background-size: cover;
      cursor: pointer;
    }
    .custom-btn-disabled {
      opacity: 0.5;
    }
    .custom-prev:hover {
      background: url("~/assets/cities/pre-btn-hover.png");
      background-size: cover;
    }
    .custom-next:hover {
      background: url("~/assets/cities/next-btn-hover.png");
      background-size: cover;
    }
  }
  .active-image {
    cursor: pointer;
  }
}

@media screen and (max-width: 1175px) {
  .site-swiper {
    img {
      height: 180px;
      object-position: center;
    }
    .btn-wrapper {
      .custom-prev,
      .custom-next {
        display: none;
      }
    }
  }
}
